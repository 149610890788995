import { useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import SeeAll from "../../../assets/icons/seeAll.svg";
import transactionActions from "../../../redux/transactions/actions";
import { Col, Row, Skeleton } from "antd";

const { getTransactionsData } = transactionActions;

function TransactionData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { transactionData } = useSelector((state) => state.transactionReducer);
  const { agentId, permissions } = useSelector((state) => {
    return {
      agentId: state.authReducer.agentId,
      permissions: state.authReducer.profileData.permissions,
    };
  });
  // console.log(agentId , "transaction overview pages ks ")

  // const skeletonArray = Array(8).fill(null);
  const skeletonArray = Array(6).fill(null);

  useEffect(() => {
    // debugger
    if (!agentId || agentId === "") {
      dispatch(getTransactionsData());
    } else {
      dispatch(getTransactionsData(0, 10, "", "", "", agentId));
    }
  }, [agentId, dispatch]);

  const HandleChange = () => {
    navigate("/transactions");
  };

  return (
    <>
      <div>
        <div className="headingTag">
          <h1>Transaction</h1>
          {permissions === true ? (
            <div onClick={HandleChange} style={{ cursor: "pointer" }}>
              See All <img src={SeeAll} alt="see all"></img>
            </div>
          ) : (
            Array.isArray(permissions)?.length > 0 &&
            permissions?.some((item) => item?.module === "transactions") && (
              <div onClick={HandleChange} style={{ cursor: "pointer" }}>
                See All <img src={SeeAll} alt="see all"></img>
              </div>
            )
          )}
        </div>
        <div className="tableStyle">
          {transactionData?.loading === false &&
          transactionData?.data?.transactions &&
          transactionData?.data?.transactions.length > 0 ? (
            transactionData?.data?.transactions
              // ?.slice(0, 8)
              ?.slice(0, 6)
              ?.map((item, key) => (
                <div className="transactionData" key={key}>
                  <div>
                    <h1>{item.transactionId}</h1>
                    <h2>{`${moment(item.createdAt).format(
                      "DD MMMM YYYY"
                    )},${moment(item.createdAt).format("hh:mm:ss A")}`}</h2>
                  </div>
                  <div className="price">
                    <h3
                      className={`${
                        item?.status?.toLowerCase() === "successful" &&
                        item?.cuResponse?.toLowerCase() === "successful"
                          ? "h3Success"
                          : item?.status?.toLowerCase() === "pending" ||
                            item?.cuResponse?.toLowerCase() === "pending"
                          ? "h3Pending"
                          : "h3Failure"
                      }`}
                    >
                      {item?.status?.toLowerCase() === "successful" &&
                        item?.cuResponse?.toLowerCase() === "successful" &&
                        "+"}
                      ₹{item?.amount / 100}
                    </h3>
                    <h4
                      className={`${
                        item?.status?.toLowerCase() === "successful" &&
                        item?.cuResponse?.toLowerCase() === "successful"
                          ? "h4Success"
                          : item?.status?.toLowerCase() === "pending" ||
                            item?.cuResponse?.toLowerCase() === "pending"
                          ? "h4Pending"
                          : "h4Failure"
                      }`}
                    >
                      {item?.status?.toLowerCase() === "successful" &&
                      item?.cuResponse?.toLowerCase() === "successful"
                        ? "Successful"
                        : item?.status?.toLowerCase() === "pending" ||
                          item?.cuResponse?.toLowerCase() === "pending"
                        ? "Pending"
                        : "Failure"}
                    </h4>
                  </div>
                </div>
              ))
          ) : (
            <>
              {transactionData?.loading === false &&
              (transactionData?.data?.length === 0 || transactionData?.data===null )? (
                <div
                  className=""
                  style={{
                    color: "rgba(0, 0, 0, 0.45)",
                    textAlign: "center",
                    fontSize: 16,
                  }}
                >
                  No data available
                </div>
              ) : (
                <>
                  {skeletonArray.map((_, index) => (
                    <div className="transactionData" key={index}>
                      <Row justify="start" className="d-flex flex-column gap-1 mb-2" gutter={[0, 0]}>
                        <Col span={24}>
                          <Skeleton.Input
                            active
                            size="small"
                            paragraph={{ rows: 1 }}
                            style={{ width: "12rem" }} // Fixed width for skeletons
                          />
                        </Col>
                        <Col span={24}>
                          <Skeleton.Input
                            active
                            size="small"
                            paragraph={{ rows: 1 }}
                            style={{ width: "12rem" }} // Fixed width for skeletons
                          />
                        </Col>
                      </Row>
                      <Row justify="start" className="d-flex flex-column gap-1 mb-2" gutter={[0, 0]}>
                        {" "}
                        <Col span={6}>
                          <Skeleton.Input
                            active
                            size="small"
                            paragraph={{ rows: 1 }}
                            style={{ width: "1rem" }} // Fixed width for skeletons
                          />
                        </Col>
                        <Col span={6}>
                          <Skeleton.Input
                            active
                            size="small"
                            paragraph={{ rows: 1 }}
                            style={{ width: "1rem" }} // Fixed width for skeletons
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionData;
