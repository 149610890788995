import React, { useEffect, useState } from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import transactionActions from "../../../redux/transactions/actions";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Select, Space } from "antd";
const { Option } = Select;

// Register necessary Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale
);

// Function to generate a list of colors
const generateColors = (count) => {
  const colors = [];
  const baseColors = [
    "#EB5A3C", // Red
    "#ffc107", // Amber
    "#77B254", // Green
    "#6A80B9", // Blue
    "#17a2b8", // Cyan
    "#6f42c1", // Purple
    "#e83e8c", // Pink
    "#fd7e14", // Orange
    "#20c997", // Teal
    "#6610f2", // Indigo
    "#6c757d", // Gray
    "#f8f9fa", // Light Gray (or Off-white)
    "#343a40", // Dark Gray
    "#d39e00", // Yellow
  ];

  for (let i = 0; i < count; i++) {
    // Pick a color from the base set and repeat or loop over as necessary
    colors.push(baseColors[i % baseColors.length]);
  }

  return colors;
};

export const PaymentModeChart = () => {
  const { getPieChartData } = transactionActions;
  const [selectionFilter, setSelectionFilter] = useState("Amount");
  const dispatch = useDispatch();

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [], // Dynamically populated colors
      },
    ],
  });

  const { agentId } = useSelector((state) => state?.authReducer);
  const { pieChartData } = useSelector((state) => state?.transactionReducer);

  // useEffect to extract data and set it in state
  useEffect(() => {
    const paymentModeStats = Array.isArray(pieChartData?.data?.paymentModeStats)
      ? pieChartData?.data?.paymentModeStats
      : [];

    if (paymentModeStats.length > 0) {
      const paymentModes = paymentModeStats.map(
        (item) => item?.paymentMode ?? "Unknown"
      );
      const transactionPercentage = paymentModeStats.map(
        (item) => item?.transactionPercentage ?? 0
      );
      const AmountPercentage = paymentModeStats.map(
        (item) => item?.amountPercentage ?? 0
      );
      const totalTransactions = paymentModeStats?.map(
        (item) => item?.totalTransactions
      );
      const totalAmount = paymentModeStats?.map((item) => item?.totalAmount);

      // Set the chart data
      setChartData({
        labels: paymentModes,
        datasets: [
          {
            totalTransactions: totalTransactions,
            totalAmount: totalAmount,
            data:
              selectionFilter === "Amount"
                ? AmountPercentage
                : transactionPercentage,
            backgroundColor: generateColors(paymentModes.length), // Dynamically generate colors based on the number of labels
          },
        ],
      });
    }
  }, [pieChartData?.data, selectionFilter]);

  useEffect(() => {
    if (!agentId || agentId === "") {
      dispatch(getPieChartData("")); // Fetching data with empty agentId or default
    } else {
      dispatch(getPieChartData(agentId)); // Fetching data with agentId
    }
  }, [agentId, dispatch]);

  return (
    <div className="d-flex flex-column gap-1">
      <h4 className="pie-h1">Payment Mode Distribution</h4>
      <Space>
        <Select
          value={selectionFilter}
          style={{ width: 130 }}
          onChange={(value) => {
            setSelectionFilter(value);
          }}
        >
          <Option value="Amount">Amount</Option>
          <Option value="Transactions">Transactions</Option>
        </Select>
      </Space>
      <div>
        <Pie
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  totalValue:
                    chartData?.datasets?.[0]?.totalTransactions?.reduce(
                      (total, value) => total + value,
                      0
                    ),
                  label: (tooltipItem, index) => {
                    const dataset = chartData?.datasets?.[0];
                    if (dataset) {
                      const totalAmount =
                        dataset.totalAmount?.[tooltipItem?.dataIndex];
                      const totalTransactions =
                        dataset.totalTransactions?.[tooltipItem?.dataIndex];
                      const label = tooltipItem?.label;
                      const rawValue = tooltipItem?.raw?.toFixed(4);
                      const totalValue =
                        selectionFilter === "Amount"
                          ? totalAmount
                          : totalTransactions;
                      const valueLabel =
                        selectionFilter === "Amount"
                          ? "Amount"
                          : "Transactions";
                      // Make sure to handle undefined values
                      return `${label} - ${rawValue}%, ${valueLabel}: ${
                        totalValue || "N/A"
                      }`;
                    }
                    return "";
                  },
                },
              },
              legend: {
                position: "bottom", // Place the legend on the right
                labels: {
                  usePointStyle: true,
                  boxWidth: 12, // Set the width of the color boxes
                  padding: 20,
                  font: {
                    size: 12,
                  },
                  generateLabels: (chart) => {
                    const labels = chart.data.labels || [];
                    const datasets = chart.data.datasets || [];
                    return labels.map((label, index) => {
                      return {
                        text: `${label}`, // Add percentage next to label
                        fillStyle: datasets[0]?.backgroundColor[index],
                      };
                    });
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

// Transaction Status Chart Component
export const TransactionStatusChart = () => {
  const { pieChartData } = useSelector((state) => state.transactionReducer);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [], // Dynamically populated colors
      },
    ],
  });

  useEffect(() => {
    const statusStats = Array.isArray(pieChartData?.data?.statusStats)
      ? pieChartData?.data?.statusStats
      : [];

    if (statusStats.length > 0) {
      const statuses = statusStats.map((item) => item?.status ?? "Unknown");
      const percentages = statusStats.map((item) => item?.percentage ?? 0);

      const statusColors = statuses.map((status) => {
        switch (status) {
          case "Successful":
            return "#77B254"; // Green
          case "Pending":
            return "#ffc107"; // Yellow
          case "Failed":
            return "#EB5A3C"; // Red
          default:
            return "#007bff"; // Default color
        }
      });

      setChartData({
        labels: statuses,
        datasets: [
          {
            data: percentages,
            backgroundColor: statusColors,
          },
        ],
      });
    }
  }, [pieChartData?.data]);

  return (
    <div className="d-flex flex-column">
      <h4 className="pie-h1">Transaction Rate</h4>
      <div>
        <Doughnut
          data={chartData}
          options={{
            aspectRatio: 1,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem) =>
                    `${tooltipItem.label} - ${tooltipItem.raw.toFixed(4)}%`,
                },
              },
              legend: {
                position: "bottom", // Place the legend on the bottom
                labels: {
                  textAlign: "center",
                  usePointStyle: true,
                  boxWidth: 12, // Set the width of the color boxes
                  padding: 20, // Adjust the padding between the labels
                  font: {
                    size: 12, // Set the font size to the equivalent of Tailwind's text-md (16px)
                  },
                  generateLabels: (chart) => {
                    const labels = chart.data.labels || [];
                    const datasets = chart.data.datasets || [];
                    return labels.map((label, index) => {
                      return {
                        text: `${label}`, // Add percentage next to label
                        fillStyle: datasets[0]?.backgroundColor[index],
                      };
                    });
                  },
                },
                // Add margin-bottom for the entire legend container
                onClick: (e) => {
                  // Access the native event and stop propagation
                  if (e.native) {
                    e.native.stopPropagation();
                  }
                }, // Prevent interaction with the legend
              },
            },
          }}
        />
      </div>
    </div>
  );
};
