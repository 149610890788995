const actions = {
  FETCH_FILE: "FETCH_FILE",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
  FETCH_DATA_RESET: "FETCH_DATA_RESET",
  FETCH_DATA_NOTIFIED: "FETCH_DATA_NOTIFIED",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_DATA_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_DATA_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_DATA_RESET: "UPLOAD_DATA_RESET",
  UPLOAD_DATA_NOTIFIED: "UPLOAD_DATA_NOTIFIED",

  getReconData: (data) => ({
    type: actions.FETCH_FILE,
    payload: data,
  }),

  getReconDataSuccess: (data) => ({
    type: actions.FETCH_DATA_SUCCESS,
    payload: { data },
  }),

  getReconDataFailure: (error) => ({
    type: actions.FETCH_DATA_FAILURE,
    payload: { error },
  }),

  getReconDataReset: () => ({
    type: actions.FETCH_DATA_RESET,
  }),

  getReconDataNotified: () => ({
    type: actions.FETCH_DATA_NOTIFIED,
  }),

  UploadFile: (data) => ({
    type: actions.UPLOAD_FILE,
    payload: data,
  }),

  UploadFileSuccess: (data) => ({
    type: actions.UPLOAD_DATA_SUCCESS,
    payload: { data },
  }),

  UploadFileFailure: (error) => ({
    type: actions.UPLOAD_DATA_FAILURE,
    payload: { error },
  }),
  uploadDataReset: () => ({
    type: actions.UPLOAD_DATA_RESET,
  }),
  uploadDataNotified: () => ({
    type: actions.UPLOAD_DATA_NOTIFIED,
  }),
};

export default actions;
