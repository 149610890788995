import "./index.css";
import Header from "./layouts/Header/Header";
import Sidebar from "./layouts/Sidebar/Sidebar";
import LoginRoutes from "./layouts/Routers/loginRoutes";
import AppRouters from "./layouts/Routers/Routers";
import Cookies from "js-cookie";
import { useEffect} from "react";
import { useDispatch } from "react-redux";
import authActions from "./redux/auth/actions";
import { useLocation } from "react-router-dom";
import { Flex, Splitter, Typography } from 'antd';
import InactivityLogout from "./utils/InActiveLogout";

function App() {
  const token = sessionStorage.getItem("biller_access");

  const { getProfile } = authActions;
  const dispatch = useDispatch();
 const location = useLocation();

  // Prevent embedding in an iframe
  useEffect(() => {
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);


  useEffect(() => {
    if (token) {
      dispatch(getProfile());
    }
  }, [location.pathname]);


  return (
    <div className="App">
      {token ? (
        <>
          <Header />
          {/* <SendBox /> */}
          <div className="partDashboard">
            <Sidebar />
            <AppRouters />
          </div>
          <InactivityLogout/>
        </>
      ) : (
        <LoginRoutes />
      )}
    </div>
  );
}
export default App;
