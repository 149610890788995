import React, { useEffect, useState, useCallback } from 'react';
import AuthActions from "../redux/auth/actions"
import { message } from 'antd';

const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
const CHECK_INTERVAL = 60 * 1000; // Check every minute

const InactivityLogout = () => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // Memoize handleLogout to prevent unnecessary re-renders
  const handleLogout = useCallback(() => {
    if (!isLoggedOut) {  // Prevent multiple logouts
      console.log('User logged out due to inactivity');
      setIsLoggedOut(true);
      sessionStorage.removeItem('biller_access');
      localStorage.removeItem('module_access_bou');
      window.location.replace(`${window.location.origin}/login`);
    }
  }, [isLoggedOut]);

  // Memoize updateActivity to prevent unnecessary re-renders
  const updateActivity = useCallback(() => {
    const currentTime = Date.now();
    // Only update if the user is still logged in
    if (!isLoggedOut) {
      setLastActivity(currentTime);
    }
  }, [isLoggedOut]);

  useEffect(() => {
    // Check if user is already logged out
    const isTokenValid = sessionStorage.getItem('biller_access') !== null;
    if (!isTokenValid) {
      handleLogout();
      return;
    }

    const checkInactivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastActivity >= INACTIVITY_TIMEOUT) {
        handleLogout();
      }
    };

    // Set up activity listeners
    const activityEvents = [
      'mousemove',
      'mousedown',
      'keydown',
      'touchstart',
      'scroll',
      'click'
    ];

    // Add event listeners
    activityEvents.forEach(event => {
      document.addEventListener(event, updateActivity, { passive: true });
    });

    // Set up inactivity checker
    const intervalId = setInterval(checkInactivity, CHECK_INTERVAL);

    // Cleanup function
    return () => {
      // Remove event listeners
      activityEvents.forEach(event => {
        document.removeEventListener(event, updateActivity);
      });
      clearInterval(intervalId);
    };
  }, [lastActivity, handleLogout, updateActivity]); // Include all dependencies

  // Optional: Show a warning modal when getting close to timeout
  useEffect(() => {
    const warningTimeout = INACTIVITY_TIMEOUT - (1 * 60 * 1000); // 1 minute before logout
    const checkForWarning = () => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (timeSinceLastActivity > warningTimeout && !isLoggedOut) {
        message.warning({
          content:'Session about to expire in 1 minute',
          duration:3
        });
        // You can add UI notification here
      }
    };

    const warningIntervalId = setInterval(checkForWarning, CHECK_INTERVAL);

    return () => {
      clearInterval(warningIntervalId);
    };
  }, [lastActivity, isLoggedOut]);

  // No need to render anything unless showing a warning
  return null;
};

export default InactivityLogout;