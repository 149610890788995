import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getDataFromBOU } from '../../Services/ApiMethodHelper';
const baseURLBOU = process.env.REACT_APP_API_BASE_URL;
const baseURLBOUExport = 'https://dmrvr7r4-3000.inc1.devtunnels.ms/'


function* getTransactionsDataResponse({ payload: { page, limit, search, fromDate, toDate,agentId } }) {
  try {

    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/transactions?page=${page ? page : 1}&limit=${limit ? limit : 10}${search ? `&search=${search}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${agentId ? `&billerId=${agentId}` : ""}`);
    if (response.status === 200) {

      yield put(actions.getTransactionsDataSuccess(response.data, response.data.total));
    } else {
      yield put(actions.getTransactionsDataFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getTransactionsDataFailed(error?.response?.data?.message));
  }
}

// function to get all transactions
// function* getAllTransactionsDataResponse({ payload: { offset, limit, search, fromDate, toDate,agentId } }) {
//   try {
//     const response = yield call(getDataFromBOU, `${baseURLBOUExport}bou/dashboard/transactions/export?${fromDate ? `fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${agentId ? `&billerId=${agentId}` : ""}`);
//     if (response.status === 200) {

//       yield put(actions.getAllTransactionsDataSuccess(response.data));
//     } else {
//       yield put(actions.getAllTransactionsDataFailed(response.message));
//     }
//   } catch (error) {
//     yield put(actions.getAllTransactionsDataFailed(error?.response?.data?.message||error));
//   }
// }

function* getChartDataResponse({payload:{agentId}}) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/graph?${agentId ? `billerId=${agentId}` : ""}`);
      yield put(actions.getChartDataSuccess(response.data));
  } catch (error) {
    yield put(actions.getChartDataFailed(error?.response?.data?.message));
  }
}

function* getDailyChartDataResponse({payload:{agentId}}) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/daily-graph?${agentId ? `billerId=${agentId}` : ""}`);
      yield put(actions.getDailyChartDataSuccess(response?.data));
  } catch (error) {
    yield put(actions.getDailyChartDataFailed(error?.response?.data?.message));
  }
}

function* getPieChartDataResponse({payload:{agentId}}) {
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/piechart?${agentId ? `billerId=${agentId}` : ""}`);
      yield put(actions.getPieChartDataSuccess(response?.data));
  } catch (error) {
    yield put(actions.getPieChartDataFailed(error?.response?.data?.message));
  }
}

// function get all transaction logs / details
function* getFullTransactionDetails({payload:{refId}}){
  try {
    const response = yield call(getDataFromBOU, `${baseURLBOU}bou/dashboard/full-transaction-history/${refId}`);
    if(response.status===201||response.status===200){
      yield put(actions.getFullTransactionsDetailsSuccess(response.data))
    }else {
      yield put(actions.getFullTransactionDetailsError(response))
    }
  } catch (error) {
      yield put(actions.getFullTransactionDetailsError(error))
  }
}



export default function* rootSaga() {
  yield all([takeEvery(actions.GET_TRANSACTIONS_DATA, getTransactionsDataResponse)]);
  // yield all([takeEvery(actions.GET_ALL_TRANSACTIONS_DATA, getAllTransactionsDataResponse)]);
  yield all([takeEvery(actions.GET_CHART_DATA, getChartDataResponse)]);
  yield all([takeEvery(actions.GET_DAILY_CHART_DATA, getDailyChartDataResponse)]);
  yield all([takeEvery(actions.GET_PIE_CHART_DATA, getPieChartDataResponse)]);
  yield all([takeEvery(actions.GET_FULL_TRANSACTIONS_DETAILS, getFullTransactionDetails)]);
}

