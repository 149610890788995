import React, { useState } from "react";
import { Modal, Form, Select, Input, Button, message } from "antd";
import axios from "axios";
import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";


const baseURLBOU = process.env.REACT_APP_API_BASE_URL

const UserModal = ({
  title,
  open,
  confirmLoading,
  handleCancel,
  handleSubmit,
  emailList,
  billerDetails,
}) => {
  const [fileInputs, setFileInputs] = useState([{ key: Date.now(), files: [] }]);
  const [form] = Form.useForm();
  const [attachmentUrls, setAttachmentUrls] = useState([]);

  const handleAddFileInput = () => {
    setFileInputs([...fileInputs, { key: Date.now(), files: [] }]);
  };

  const handleFileChange = (index, event) => {
    const newFileList = Array.from(event.target.files);
    const updatedFileInputs = fileInputs.map((input, i) => {
      if (i === index) {
        return { ...input, files: newFileList };
      }
      return input;
    });
    setFileInputs(updatedFileInputs);
  };

  const handleUploadFiles = async () => {
    const formData = new FormData();
    fileInputs.forEach((input, index) => {
      input.files.forEach((file) => {
        formData.append("files", file);
      });
    });
    try {
      const response = await axios.post(
        `${baseURLBOU}bou/dashboard/api/communication-channel/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        const urls = response.data.urls;
        // console.log(urls , "data of phptot ")
        setAttachmentUrls(urls); 
        return urls;
      }
    } catch (error) {
      // console.error("File upload failed:", error);
      message.error("File upload failed.");
      return [];
    }
  };

  const handleFormSubmit = async (values) => {
    let data = {
      billerDetails,
      subject: values.subject,
      body: values.message,
      attachments: attachmentUrls 
    };
    // Check if there are files to upload
    const hasFiles = fileInputs.some((input) => input.files.length > 0);
    if (hasFiles) {
      const uploadedUrls = await handleUploadFiles(); 
      if (uploadedUrls?.length > 0) {
        data.attachments = uploadedUrls; 
      } else {
        return; 
      }
    }
    // console.log(data, "Uploaded Data"); 
    handleSubmit(data); 
  };
  

  return (
    <Modal
      title={title}
      open={open}
      onCancel={()=>{handleCancel();form.resetFields();setFileInputs([{ key: Date.now(), files: [] }]);}}
      width={700}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          style={{
            backgroundColor: "#073763",
            borderColor: "#073763",
            color: "white",
          }}
          onClick={() => form.submit()}
          loading={confirmLoading}
        >
          Send
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        autoComplete="off"
      >
        <Form.Item
          name="emails"
          label="Email Addresses"
          // initialValue={emailList}
          rules={[{ required: true, message: "Please select email addresses" }]}
        >
          <Select mode="multiple" placeholder="Select Email Addresses">
            {emailList.map((email) => (
              <Select.Option key={email} value={email}>
                {email}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="subject"
          label="Subject"
          rules={[{ required: true, message: "Please enter the subject" }]}
        >
          <Input placeholder="Enter Subject" />
        </Form.Item>

        <Form.Item
          name="message"
          label="Message"
          rules={[{ required: true, message: "Please enter the message" }]}
        >
          <Input.TextArea rows={4} placeholder="Enter Message" />
        </Form.Item>

        {fileInputs.map((input, index) => (
          <Form.Item key={input.key} label={`Attach File ${index + 1}`}>
            <input
              type="file"
              multiple
              onChange={(e) => handleFileChange(index, e)}
            />
          </Form.Item>
        ))}

        <Form.Item>
          <Button
            type="dashed"
            onClick={handleAddFileInput}
            style={{ width: "100%", marginBottom: 8 }}
          >
            <PlusOutlined /> Add File
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

UserModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailList: PropTypes.array.isRequired,
  userDetails: PropTypes.array.isRequired,
};

export default UserModal;
