"use client";
import React, { useState, useEffect } from "react";
import { Table, Space, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/ReconAndSettlement/actions";
import Uploads from "./Upload";
import Filter from "./Filter";
import Pako from "pako";

// Columns configuration
const columns = [
  { title: "Name", dataIndex: "name" },
  { title: "From Time", dataIndex: "fromTime" },
  { title: "To Time", dataIndex: "toTime" },
  { title: "Duration In Hrs.", dataIndex: "duration" },
];

const DevelopmentTable = () => {
  const dispatch = useDispatch();
  const { fetchReconData } = useSelector((state) => {
    return {
      fetchReconData: state.reconReducer?.fetchReconData,
    };
  });
  const { getReconData } = actions;
  const [data, setData] = useState([]);
  const [selectedFile, setSelectedFile] = useState({
    cycleNo: 1,
    fileType: "JSON",
    date: new Date().toISOString(),
  });
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showTable, setShowTable] = useState(true);

  const { profileData } = useSelector((state) => {
    return {
      profileData: state.authReducer?.profileData,
    };
  });

  useEffect(() => {
    const fetchedData = fetchReconData?.data?.url?.map((file, index) => ({
      name: `Transaction-${index + 1}`,
      fromTime: "N/A",
      toTime: "N/A",
      duration: "N/A",
      date: file.date || "N/A",
      fileUrl: file,
    }));
    setData(fetchedData);
  }, [fetchReconData?.data]);

  // Handle Single File Download with Proxy
  const handleDownload = async (fileUrl) => {
    // const fileUrl = "https://storage.googleapis.com/bbps-dev-b01/recon-settlements/data_2025-01-21T11:08:31.782Z.json.gz"
    try {
      // Fetch the file content
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch the file. Status: ${response.status}`);
      }

      // Convert response to blob
      const blob = await response.blob();

      if (blob.size === 0) {
        throw new Error("Received empty file.");
      }

      // Create a temporary URL for the blob
      const downloadUrl = window.URL.createObjectURL(blob);

      // Extract the file name or use a default name
      const fileName = fileUrl.split("/").pop() || "downloaded-file.json";

      // Create an anchor element and simulate a click
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor); // Attach to the DOM
      anchor.click(); // Trigger the download
      document.body.removeChild(anchor); // Clean up the DOM

      // Revoke the object URL
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error.message);
    }
  };

  return (
    <div className="">
      {profileData?.internal === true && (
        <div className="toggle-container">
          <Button
            onClick={() => setShowTable(!showTable)}
            className="toggle-button"
          >
            {showTable ? "Switch to Upload" : "Switch to Table"}
          </Button>
        </div>
      )}
      {showTable ? (
        <div className="dev-table">
          <Filter
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={fetchReconData?.loading}
          />
          <Table
            columns={[
              ...columns,
              {
                title: "Actions",
                render: (_, record) => (
                  <Space>
                    <Button
                      onClick={() => handleDownload(record.fileUrl)}
                      style={{ cursor: "pointer" }}
                      className="filter-button"
                    >
                      Download File
                    </Button>
                  </Space>
                ),
              },
            ]}
            rowKey={(record) => record?.name}
            dataSource={data}
            loading={loading}
          />
        </div>
      ) : (
        <div className="upload-container">
          <Uploads />
        </div>
      )}
    </div>
  );
};

export default DevelopmentTable;
