import React, { useState } from "react";
import DevelopmentTable from "./ReconComponents/DevelopmentTable";
import "./ReconComponents/index.scss";

function Recon() {
  const [formData, setFormData] = useState({ 
    cycleNo: "", 
    fileType: "",
    date: "" 
  });
  const [file, setFile] = useState(null);
  const [formDataState, setFormDataState] = useState([]);

  const handleSubmit = () => {
    if (!file) {
      console.log("No file selected.");
      return;
    }

    const form = new FormData();
    form.append("cycleNo", formData.cycleNo);
    form.append("fileType", formData.fileType);
    form.append("date", formData.date);
    form.append("file", file);

    // API call or further logic
    console.log("Form Submitted");
  };

  return (
    <div className="dev-table">
      <DevelopmentTable
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
          setFormDataState={setFormDataState}
          formDataState={formDataState}/>
    </div>
  );
}

export default Recon;
