import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { postWithOutToken, getData, patchData,  getDatawithToken, postDatawithToken, postData } from '../../Services/ApiMethodHelper';
import CryptoJS from 'crypto-js';
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// const baseURL = process.env.REACT_APP_AUTH_SERVER_LOCAL;
const baseURL = process.env.REACT_APP_AUTH_SERVER_PRODUCTION;

function* loginResponse({ payload: { data } }) {
  try {
    const response = yield call(postWithOutToken, '/auth/banks/login', data);
    if (response.status === 200) {
      const passPhrase = "AeS5wL6J7N2nQr5U8x/A?D(G+KbPeShV";
      const bytes = CryptoJS.AES.decrypt(response.data.token, passPhrase);
      const encryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      let decodedToken = jwtDecode(encryptedToken);
      // console.log("decode token ", decodedToken)
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify({ role: decodedToken.role, role_id: decodedToken.roleId,isOffline:decodedToken?.isOffline, permissions: decodedToken.permissions }), process.env.REACT_APP_ENCRYPTED_KEY).toString();
      // Store the encrypted data in local storage
      localStorage.setItem('module_access_bou', encryptedData);
      localStorage.setItem('access_token', response.data.token);
      // Cookies.set("biller_access", response.data.token, { expires: 20 / 24 });
      if (!decodedToken.isTwoFactorAuthenticationEnabled) {
        const responseList = yield call(getDatawithToken, '/auth/setup/2fa', response.data.token);
        if (responseList.status === 200) {
          yield put(actions.loginSuccess(responseList.data));
        }
      } else {
        yield put(actions.loginSuccess(response.data));
      }
    } else {
      yield put(actions.loginFailed(response.message));
    }
  } catch (error) {
    console.log(error)
    yield put(actions.loginFailed(error));

  }
}

function* getProfileResponse() {
  try {
    const response = yield call(getData, '/users/me');
    if (response.status === 200) {
      yield put(actions.getProfileSuccess(response.data));
    } else if (response.status === 404 || 401 || 400) {
      sessionStorage.removeItem("biller_access")
      localStorage.removeItem("module_access_bou")
      window.location.replace("/login")
    } else {
      yield put(actions.getProfileFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getProfileFailed(error));
    if(error === "Session timeout. Please login again" || error?.includes("expired")){
      // Remove token from cookies storage
      sessionStorage.removeItem('biller_access');
      localStorage.removeItem('module_access_bou')
      // Redirect to login page
      window.location.href = '/login';
    }
  }
}

function* VerifyCodeResponse({ payload: { data, verify } }) {
  const token = localStorage.getItem('access_token');
  try {
    if (verify) {
      const response = yield call(getDatawithToken, '/auth/validate/2fa', token);
      if (response.status === 200) {
        const responseList = yield call(postDatawithToken, '/auth/verify/2fa', token, data);
        if (responseList.status === 200) {
          yield put(actions.verifyCodeSuccess(responseList.data));
          sessionStorage.setItem("biller_access", token);
          localStorage.removeItem('access_token')
          window.location.href = '/';
        } else {
          yield put(actions.verifyCodeFailed(response.message));
        }
      } else {
        yield put(actions.verifyCodeFailed(response.message));
      }
    }
    else {
      const responseList = yield call(postDatawithToken, '/auth/verify/2fa', token, data);
      if (responseList.status === 200) {
        yield put(actions.verifyCodeSuccess(responseList.data));
        sessionStorage.setItem("biller_access", token);
        localStorage.removeItem('access_token')
        window.location.href = '/';
      } else {
        yield put(actions.verifyCodeFailed(responseList.message));
      }
    }

  } catch (error) {
    yield put(actions.verifyCodeFailed(error));

  }
}




function* changePasswordResponse({ payload: { data } }) {
  try {
    const response = yield call(patchData, `${baseURL}/users/change-password`, data);
    if (response.status === 200) {
      yield put(actions.changePasswordSuccess("Password updated succcessfully"));
    } else {
      yield put(actions.changePasswordFailed(response.message));
    }
  } catch (error) {
    yield put(actions.changePasswordFailed(error));

  }
}

function* getAgentDataResponse({
  payload: { offset, limit, search },
}) {

  try {
    const response = yield call(getData, `/admin/organizations?offset=${offset ? offset : 0}&limit=${limit ? limit : 20}${search ? `&search=${search}` : ''}`);
    yield put(actions.getAgentDataSuccess(response?.data));
  } catch (error) {
    yield put(actions.getAgentDataFailed(error));
  }
}

function* getAgentId(action) {
  try {
    const { agentId } = action.payload;
    yield put(actions.selectAgentSuccess(agentId)); // Dispatch success action with agentId
  } catch (error) {
    yield put(actions.selectAgentFailure(error.message)); // Dispatch failure action
  }
}

function* forgotPassword({ payload: { data } }) {
  try {
    const response = yield call(postWithOutToken, '/auth/forgot-password', data);
    if (response && response.status === 200) {
      yield put(actions.forgotPasswordSuccess(response.data))
    } else {
      yield put(actions.forgotPasswordFailed(response || 'unknown error'))
    }
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error || 'unknown error'))
  }
}

function* resetPassword({ payload: { data } }) {
  try {
    const response = yield call(postWithOutToken, `/auth/reset-password?token=${data?.token}`, data?.values);
    if (response && response.status === 200) {
      yield put(actions.resetPasswordSuccess(response.data))
    } else {
      yield put(actions.resetPasswordFailed(response))
    }
  } catch (error) {
    yield put(actions.resetPasswordFailed(error))
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actions.LOGIN, loginResponse)]);
  yield all([takeEvery(actions.GET_PROFILE, getProfileResponse)]);
  yield all([takeEvery(actions.CHANGE_PASSWORD, changePasswordResponse)]);
  yield all([takeEvery(actions.VERIFY_CODE, VerifyCodeResponse)]);
  yield all([takeEvery(actions.GET_AGENT_DATA, getAgentDataResponse)]);
  yield all([takeEvery(actions.SELECT_AGENT_REQUEST, getAgentId)]);
  yield all([takeEvery(actions.FORGOT_PASSWORD, forgotPassword)])
  yield all([takeEvery(actions.RESET_PASSWORD, resetPassword)])
}
