import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import Cookies from 'js-cookie';
import { getData, postDatawithToken, patchDatawithToken ,  patchData, deleteData,  } from '../../Services/ApiMethodHelper';



function* getUserDataResponse({ payload: { offset, limit, search } }) {
  try {

    const response = yield call(getData, `/banks/users?offset=${offset ? offset : 0}&limit=${limit ? limit : 10}${search ? `&search=${search} ` : ''}`); //?offset=${offset}&limit=${limit}
    if (response.status === 200) {

      yield put(actions.getUserDataSuccess(response.data.users, response.data.total));
    } else {
      yield put(actions.getUserDataFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getUserDataFailed(error));
  }
}

function* addUserResponse({ payload: { data } }) {
  const token = sessionStorage.getItem("biller_access");
  try {
    const response = yield call(postDatawithToken, `/banks/users`, token , data);
    if (response.status === 201) {
      yield put(actions.addUserSuccess("User added succcessfully"));
      // Dispatch GET_USER_DATA action to fetch updated data
      yield put(actions.getUserData());

    } else {
      yield put(actions.addUserFailed(response.message));
    }
  } catch (error) {
    yield put(actions.addUserFailed(error));
  }
}


function* editUserResponse({ payload: { userId, data } }) {
  const token = sessionStorage.getItem("biller_access");
  try {
    const response = yield call(patchDatawithToken, `/banks/users/${userId}`, token, data);
    if (response.status === 200) {
      yield put(actions.editUserSuccess("User update succcessfully"));
      // Dispatch GET_USER_DATA action to fetch updated data
      yield put(actions.getUserData());

    } else {
      yield put(actions.editUserFailed(response.message));
    }
  } catch (error) {
    yield put(actions.editUserFailed(error));
  }
}

function* deleteUserResponse({ payload: { id } }) {
  // console.log(id)
  try {
    const response = yield call(deleteData, `/banks/users/${id}`);
    if (response.status === 200) {
      yield put(actions.deleteUserSuccess("User deactive sucessfully"));
      // Dispatch GET_USER_DATA action to fetch updated data
      yield put(actions.getUserData(0, 10));

    } else {
      yield put(actions.deleteUserFailed(response.message));
    }
  } catch (error) {
    yield put(actions.deleteUserFailed(error));
  }
}



function* userStatusChangeResponse({ payload: { data } }) {
  // console.log(data , "data coming from data ")
  let statusData = {
   status: "Active",
   role:data?.role
  }
  
  try {
    const response = yield call(patchData, `${process.env.REACT_APP_AUTH_SERVER_PRODUCTION}/banks/users/${data?._id}`, statusData);
    if (response.status === 200) {
      yield put(actions.userStatusChangeSuccess("User deactive sucessfully"));
      // Dispatch GET_USER_DATA action to fetch updated data
      yield put(actions.getUserData(0, 10));

    } else {
      // debugger
      yield put(actions.userStatusChangeFailed(response.message));
    }
  } catch (error) {
    // debugger
    yield put(actions.userStatusChangeFailed(error));
  }
}



function* getRolesResponse() {
  try {
    const response = yield call(getData, `/roles`);
    if (response.status === 200) {

      yield put(actions.getRolesSuccess(response.data));
    } else {
      yield put(actions.getRolesFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getRolesFailed(error));
  }
}

function* getModulesResponse() {
  try {

    const response = yield call(getData, `/modules`);
    if (response.status === 200) {

      yield put(actions.getModulesSuccess(response.data));
    } else {
      yield put(actions.getModulesFailed(response.message));
    }
  } catch (error) {
    yield put(actions.getModulesFailed(error));
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actions.GET_USER_DATA, getUserDataResponse)]);
  yield all([takeEvery(actions.ADD_USER, addUserResponse)]);
  yield all([takeEvery(actions.EDIT_USER, editUserResponse)]);
  yield all([takeEvery(actions.DELETE_USER, deleteUserResponse)]);
  yield all([takeEvery(actions.USER_STATUS_CHANGE, userStatusChangeResponse)]);
  yield all([takeEvery(actions.GET_MODULES, getModulesResponse)]);
  yield all([takeEvery(actions.GET_ROLES, getRolesResponse)]);
}
