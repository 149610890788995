import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, message, Popover, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import transactionActions from "../../redux/transactions/actions";
import moment from "moment";
import { generateFilename } from "../../utils/exportFileName";
import { Link } from "react-router-dom";
import { BlobFileDownload } from "./BlobFileDownload";

const TransactionExport = () => {
  const [fullSheetDateExport, setFullSheetDateExport] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [fileExportMessage, setFileExportMessage] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { getAllTransactionsData, resetAllTransactionsData } =
    transactionActions;
  const { agentId } = useSelector((state) => {
    return {
      agentId: state.authReducer.agentId,
    };
  });
  const { transactionData, allTransactions } = useSelector((state) => {
    return {
      transactionData: state.transactionReducer?.transactionData,
      allTransactions: state.transactionReducer?.allTransactions,
    };
  });

  //   dynamic cols
  const getDynamicColumns = (transactions) => {
    const tags = [];

    // Iterate over all transactions to collect tags
    transactions?.forEach((transaction) => {
      transaction?.customerParams?.tag?.forEach((tag) => {
        // Avoid duplicates by checking if the tag name already exists
        if (!tags.some((existingTag) => existingTag?.name === tag?.name)) {
          tags.push(tag);
        }
      });
    });

    // Map tags to columns
    return tags?.map((tag) => ({
      title: tag?.name, // Column title will be the name of the tag
      dataIndex: tag?.name, // Column dataIndex will be the value (or unique identifier)
    }));
  };

  // Memoize the dynamic columns to avoid recalculating on every render
  const dynamicColumns = useMemo(
    () => getDynamicColumns(transactionData?.transactions),
    [transactionData]
  );

  const exportData =
    transactionData?.data?.transactions &&
    transactionData?.data?.transactions?.length > 0 &&
    transactionData?.data?.transactions?.map((items, index) => {
      // Treating customerParams?.tag as an array
      const customerTags = items?.customerParams?.tag || [];

      // Create an object with the static data
      let rowData = {
        TransactionDateTime: items?.createdAt,
        TransactionId: items?.transactionId,
        // ParticipantCode: items?.ParticipantCode,
        PaymentMode: items?.paymentMode,
        Biller: items?.biller?.billerName,
        "BillAmount (₹)": items?.billAmount ? items?.billAmount / 100 : "",
        "PaidAmount (₹)": items?.amount ? items?.amount / 100 : "",
        // IdfReceiptNumber: items?.idfrecieptnumber || "",
        BillerCategory: items?.biller?.billerCategoryName,
        // EnTransId: items?.EnTransId || "",
        BillerId: items?.billerId,
        TranStatus:
          items?.status.toLowerCase() === "successful" &&
          items?.cuResponse?.toLowerCase() === "successful"
            ? "Successful"
            : items?.status.toLowerCase() === "pending" ||
              items?.cuResponse?.toLowerCase() === "pending"
            ? "Pending"
            : "Failure",
        ResponseMsg:
          items?.status.toLowerCase() === "successful" &&
          items?.cuResponse?.toLowerCase() === "successful"
            ? "Successful"
            : items?.status.toLowerCase() === "pending" ||
              items?.cuResponse?.toLowerCase() === "pending"
            ? "Pending"
            : "Failure",
      };

      // Dynamically add each tag value to the rowData
      customerTags.forEach((tag) => {
        rowData[tag?.name] = tag?.value;
      });

      // Now include the dynamic columns in the export data
      return {
        ...rowData,
        ...dynamicColumns.reduce((acc, column) => {
          // Search for the tag that matches the column name (title)
          const tagValue = customerTags.find(
            (tag) => tag?.name === column?.title
          );
          // If tag value is found, add it, otherwise add an empty string
          if (tagValue) {
            acc[column?.title] = tagValue?.value || "";
          } else {
            acc[column?.title] = ""; // Empty if no tag value for this column
          }
          return acc;
        }, {}),
      };
    });

  const exportAllTransactions =
    allTransactions?.data?.transactions &&
    allTransactions?.data?.transactions?.length > 0 &&
    allTransactions?.data?.transactions?.map((items, index) => {
      // Treating customerParams?.tag as an array
      const customerTags = items?.customerParams?.tag || [];

      // Create an object with the static data
      let rowData = {
        TransactionDateTime: items?.createdAt,
        TransactionId: items?.transactionId,
        // ParticipantCode: items?.ParticipantCode,
        PaymentMode: items?.paymentMode,
        Biller: items?.biller?.billerName,
        "BillAmount (₹)": items?.billAmount ? items?.billAmount / 100 : "",
        "PaidAmount (₹)": items?.amount ? items?.amount / 100 : "",
        // IdfReceiptNumber: items?.idfrecieptnumber || "",
        BillerCategory: items?.biller?.billerCategoryName,
        // EnTransId: items?.EnTransId || "",
        BillerId: items?.billerId,
        TranStatus:
          items?.status?.toLowerCase() === "successful" &&
          items?.cuResponse?.toLowerCase() === "successful"
            ? "Successful"
            : items?.status?.toLowerCase() === "pending" ||
              items?.cuResponse?.toLowerCase() === "pending"
            ? "Pending"
            : "Failure",
        ResponseMsg:
          items?.status?.toLowerCase() === "successful" &&
          items?.cuResponse?.toLowerCase() === "successful"
            ? "Successful"
            : items?.status?.toLowerCase() === "pending" ||
              items?.cuResponse?.toLowerCase() === "pending"
            ? "Pending"
            : "Failure",
      };

      // Dynamically add each tag value to the rowData
      customerTags.forEach((tag) => {
        rowData[tag?.name] = tag?.value;
      });

      // Now include the dynamic columns in the export data
      return {
        ...rowData,
        ...dynamicColumns.reduce((acc, column) => {
          // Search for the tag that matches the column name (title)
          const tagValue = customerTags.find(
            (tag) => tag?.name === column?.title
          );
          // If tag value is found, add it, otherwise add an empty string
          if (tagValue) {
            acc[column?.title] = tagValue?.value || "";
          } else {
            acc[column?.title] = ""; // Empty if no tag value for this column
          }
          return acc;
        }, {}),
      };
    });
  const handleFullSheetExport = (date) => {
    setFullSheetDateExport(date);
  };

  //   disable date
  const disabledToDate = (current) => {
    return (
      (fullSheetDateExport &&
        current &&
        (current < fullSheetDateExport.startOf("day") ||
          current > moment().startOf("day"))) ||
      (current && current > moment().startOf("day"))
    );
  };

  // useEffect(() => {
  //   if (fullSheetDateExport !== null) {
  //     dispatch(
  //       getAllTransactionsData(
  //         1,
  //         "",
  //         "",
  //         fullSheetDateExport?.format("YYYY-MM-DD"),
  //         fullSheetDateExport?.format("YYYY-MM-DD"),
  //         agentId
  //       )
  //     );
  //   }
  // }, [fullSheetDateExport]);

  useEffect(() => {
    if (
      allTransactions?.loading === false &&
      (allTransactions?.error !== null || allTransactions?.data?.total === 0)
    ) {
      setTimeout(() => {
        dispatch(resetAllTransactionsData());
      }, 3000);
      setFullSheetDateExport(null);
    } else if (
      allTransactions?.loading === false &&
      allTransactions?.error === null &&
      allTransactions?.data?.total !== 0 &&
      fullSheetDateExport !== null
    ) {
      setTimeout(() => {
        dispatch(resetAllTransactionsData());
      }, 20000);
      setFullSheetDateExport(null);
    }
  }, [allTransactions]);

  // Trigger export when fullSheetDateExport changes
  useEffect(() => {
    if (fullSheetDateExport && fullSheetDateExport?.format("YYYY-MM-DD")) {
      const fileUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }bou/dashboard/transactions/export?fromDate=${fullSheetDateExport?.format(
        "YYYY-MM-DD"
      )}&toDate=${fullSheetDateExport?.format("YYYY-MM-DD")}${
        agentId ? `&billerId=${agentId}` : ""
      }`;

      setExporting(true); // Start exporting, set loading message to show

      BlobFileDownload(fileUrl, sessionStorage.getItem("biller_access"))
        .then((response) => {
          // Handle successful response
          if (response === "Download successful") {
            setExporting(false); // Stop exporting, hide loading message
            setFileExportMessage("File Exported!");
            setTimeout(() => {
              setFileExportMessage("");
            }, 3000);
            setFullSheetDateExport(null); // Reset the export date
            messageApi.open({
              type: "success",
              content: "File Exported Successfully",
              duration: 2,
            });
          }
        })
        .catch((error) => {
          // Handle failure response
          setExporting(false); // Stop exporting, hide loading message
          setFileExportMessage("File Exported failed!");
          setFullSheetDateExport(null); // Reset the export date
          messageApi.open({
            type: "error",
            content: `File export failed: ${error}`,
            duration: 2,
          });
          setTimeout(() => {
            setFileExportMessage("");
          }, 3000);
        });
    }
  }, [fullSheetDateExport, messageApi]);

  // useEffect(() => {

  //   if (fullSheetDateExport && fullSheetDateExport.length === 2) {
  //     const [startDate, endDate] = fullSheetDateExport;

  //     // Ensure the dates are in the correct format before making the API call
  //     const formattedStartDate = startDate.format("YYYY-MM-DD");
  //     const formattedEndDate = endDate.format("YYYY-MM-DD");

  //     const fileUrl = `${
  //       process.env.REACT_APP_API_BASE_URL
  //     }bou/dashboard/transactions/export?fromDate=${formattedStartDate}&toDate=${formattedEndDate}${
  //       agentId ? `&billerId=${agentId}` : ""
  //     }`;

  //     setExporting(true); // Start exporting, set loading message to show

  //     BlobFileDownload(fileUrl, sessionStorage.getItem("biller_access"))
  //       .then((response) => {
  //         // Handle successful response
  //         if (response === "Download successful") {
  //           setExporting(false); // Stop exporting, hide loading message
  //           setFileExportMessage("File Exported!");
  //           setTimeout(() => {
  //             setFileExportMessage("");
  //           }, 3000);
  //           setFullSheetDateExport(null); // Reset the export date
  //           messageApi.open({
  //             type: "success",
  //             content: "File Exported Successfully",
  //             duration: 2,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         // Handle failure response
  //         setExporting(false); // Stop exporting, hide loading message
  //         setFileExportMessage("File Exported failed!");
  //         setFullSheetDateExport(null); // Reset the export date
  //         messageApi.open({
  //           type: "error",
  //           content: `File export failed: ${error}`,
  //           duration: 2,
  //         });
  //         setTimeout(() => {
  //           setFileExportMessage("");
  //         }, 3000);
  //       });
  //   }
  // }, [fullSheetDateExport, messageApi, agentId]);

  return (
    <>
      {contextHolder}
      <div>
        {exportData?.length > 0 ? (
          <Popover
            placement="bottom"
            content={
              <div
                className="exportBtnDiv"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  padding: "10px",
                  zIndex: 10,
                  gap: "20px",
                }}
              >
                {exportData?.length > 0 ? (
                  <>
                    {exporting ? (
                      <span
                        className="btn not-allowed hover:not-allowed"
                        style={{ textWrap: "nowrap", textAlign: "center" }}
                      >
                        <DownloadOutlined /> &nbsp; Current Page
                      </span>
                    ) : (
                      <CSVLink
                        filename={`${generateFilename("currentPage")}`}
                        data={exportData}
                        className="btn exportBtn"
                        style={{ textWrap: "nowrap" }}
                      >
                        <DownloadOutlined /> &nbsp; Current Page
                      </CSVLink>
                    )}
                    {exporting &&
                    fileExportMessage === "" &&
                    fullSheetDateExport !== null ? (
                      <span
                        style={{
                          width: "180px",
                          textAlign: "center",
                        }}
                      >
                        Exporting... <Spin size="16" />
                      </span>
                    ) : (
                      <>
                        {allTransactions?.loading === false &&
                          allTransactions?.data === null && (
                            <>
                              {exporting === false &&
                              fileExportMessage !== "" ? (
                                <span
                                  className="border py-1 rounded"
                                  style={{
                                    width: "180px",
                                    textAlign: "center",
                                    color: `${
                                      fileExportMessage === "File Exported!"
                                        ? "green"
                                        : "red"
                                    }`,
                                  }}
                                >
                                  {fileExportMessage}
                                </span>
                              ) : (
                                <Popover
                                  placement="bottom"
                                  trigger={"click"}
                                  content={
                                    <DatePicker
                                      value={fullSheetDateExport}
                                      style={{
                                        width: "100%",
                                        border: "none",
                                      }}
                                      onChange={handleFullSheetExport}
                                      disabledDate={disabledToDate}
                                      format="DD-MM-YYYY"
                                      placeholder="(DD-MM-YYYY)" // Placeholder added here
                                      suffixIcon={
                                        <CalendarOutlined
                                          style={{ color: "#d9d3d3" }}
                                        />
                                      }
                                    />
                                    // <RangePicker
                                    //   value={fullSheetDateExport}
                                    //   style={{
                                    //     width: "100%",
                                    //     border: "none",
                                    //   }}
                                    //   onChange={handleFullSheetExport}
                                    //   format="DD-MM-YYYY"
                                    //   placeholder="(DD-MM-YYYY)"
                                    //   suffixIcon={
                                    //     <CalendarOutlined
                                    //       style={{ color: "#d9d3d3" }}
                                    //     />
                                    //   }
                                    //   disabledDate={(date) =>
                                    //     date > moment().endOf("day")
                                    //   }
                                    // />
                                  }
                                >
                                  <Button
                                    // disabled={
                                    //   alertNotify === true &&
                                    //   (toDate === null ||
                                    //     fromDate === null ||
                                    //     dateDiffDays > 1)
                                    // }
                                    // disabled={true}

                                    style={{
                                      width: "180px",
                                    }}
                                    // onClick={functionToGetAllTransactionsData}
                                  >
                                    Fetch By Date
                                  </Button>
                                </Popover>
                              )}
                            </>
                          )}
                        {allTransactions?.data?.total === 0 &&
                          allTransactions?.loading === false && (
                            <div
                              className="d-flex justify-content-center border rounded py-1"
                              style={{ width: "180px" }}
                            >
                              <span>No data found 😐</span>
                            </div>
                          )}
                      </>
                    )}

                    {allTransactions?.loading === false &&
                      allTransactions?.data?.total !== 0 &&
                      exportAllTransactions?.length > 0 && (
                        <CSVLink
                          filename="fullsheetdata.csv"
                          data={exportAllTransactions}
                          className="btn exportBtn"
                          style={{ textWrap: "nowrap", width: "180px" }}
                        >
                          <DownloadOutlined /> &nbsp; Export Now
                        </CSVLink>
                        // <Button onClick={()=>handleExportFile()}>
                        //   <DownloadOutlined /> &nbsp; Export Sheet
                        // </Button>
                      )}
                  </>
                ) : (
                  <div className="btn not-allowed">
                    <DownloadOutlined />
                    <span>Export</span>
                  </div>
                )}
              </div>
            }
            trigger={"click"}
          >
            <Button
              className="btn exportBtn"
              style={{
                padding: "10",
                border: "1px solid green",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              Export
              <FaAngleDown />
            </Button>
          </Popover>
        ) : (
          <span
            className="btn"
            style={{
              padding: "10",
              gap: "4px",
              border: "1px solid #525252",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              cursor: "not-allowed",
              color: "white",
              backgroundColor: "#525252",
            }}
          >
            <DownloadOutlined />
            Export
          </span>
        )}
      </div>
    </>
  );
};

export default TransactionExport;
