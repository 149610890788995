import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./index.scss";
import { Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import chartAction from "../../../redux/transactions/actions";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
const { getChartData } = chartAction;

const BillingCycleGraph = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chartData } = useSelector((state) => state.transactionReducer);
  const { agentId } = useSelector((state) => state.authReducer);

  const [selectedOption, setSelectedOption] = useState("yearly");
  const [series, setSeries] = useState([
    {
      name: "Amount",
      data: [],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      colors: ["#42BE65"],
    },
    grid: {
      row: {
        colors: ["transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
    },
    markers: {
      size: 8,
      colors: ["#42BE65"],
      strokeWidth: 2,
      stroke: "#ffffff",
    },
  });
  const navigateToTransactionPage = () => {
    navigate("/transactions");
  };

  useEffect(() => {
    if (!agentId || agentId == "") {
      dispatch(getChartData());
    } else {
      dispatch(getChartData(agentId));
    }
  }, [agentId, dispatch]);

  const handleSelectChange = (event) => {
    setSelectedOption(event);
  };

  useEffect(() => {
    if (chartData?.data !== null) {
      if (selectedOption === "monthly" && chartData?.data?.sixMonth) {
        setSeries([
          {
            name: "Amount",
            data: chartData?.data?.sixMonth?.data?.map((item) => item / 100),
          },
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions?.xaxis,
            categories: chartData?.data?.sixMonth?.time,
          },
        }));
      } else if (selectedOption === "yearly" && chartData?.data?.sixYear) {
        const yearLabels = chartData?.data?.sixYear?.time?.map((year) =>
          year?.toString()
        );
        setSeries([
          {
            name: "Amount",
            data: chartData?.data?.sixYear?.data?.map((item) => item / 100),
          },
        ]);

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions?.xaxis,
            categories: yearLabels,
          },
        }));
      }
    }
  }, [chartData, selectedOption]);

  return (
    <>
      <div className="headingGrpah">
        <h4>Billing Cycle</h4>
        {chartData?.data?.sixYear && chartData?.data?.sixMonth && (
          <div>
            <Select
              value={selectedOption}
              onChange={handleSelectChange}
              style={{ width: 120 }}
            >
              <Option value="monthly">Monthly</Option>
              <Option value="yearly">Yearly</Option>
            </Select>
          </div>
        )}
        {/* <h5>Quarterly</h5> */}
      </div>

      <div className="cssGraph">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={480}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </>
  );
};

export default BillingCycleGraph;
