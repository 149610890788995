import axios from "axios";
import Cookies from "js-cookie";

// const baseURL = process.env.REACT_APP_AUTH_SERVER_LOCAL;
const baseURL = process.env.REACT_APP_AUTH_SERVER_PRODUCTION;

const token = sessionStorage.getItem("biller_access");

// error Message
const getErrorMessage = (error) => {
  var message;
  if (error.response && error.response.status) {
    message = error.response.data.message;
  } else {
    message = "Internal server error!";
  }

  return message;
};
// Middleware function to handle API calls
const handleApiCall = async (url, method, data) => {
  try {
    const response = await axios[method](baseURL + url, data);
    if (response.status === 400 || response.status === 500) {
      throw response;
    }
    return response;
  } catch (err) {
    // throw err.data.message
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");
      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// Middleware function to handle API calls
const handleApiCallWithToken = async (url, method, data) => {
  try {
    const response = await axios[method](url, data, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 400 || response.status === 500) {
      throw response;
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage?.includes("expired")
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// Middleware function to handle API calls
const handleDeleteApiCallWithToken = async (url, method) => {
  try {
    // debugger
    const response = await axios[method](baseURL + url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 400 || response.status === 500) {
      throw response;
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// Login Method
const postWithOutToken = async (url, data) => {
  return handleApiCall(url, "post", data);
};

// getDatawith token paasing
const getDatawithToken = async (url, newToken) => {
  try {
    const response = await axios.get(baseURL + url, {
      headers: { Authorization: `Bearer ${newToken}` },
    });
    if (response.status === 400 || response.status === 500) {
      throw response;
    } else if (response?.status === 401) {
      sessionStorage.removeItem("biller_access");
      localStorage.removeItem("module_access_bou");
      window.location.href = "/login";
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// getDatawith token paasing
const postDatawithToken = async (url, newToken, data) => {
  // console.log(url, newToken, data , "dlld")
  try {
    const response = await axios.post(baseURL + url, data, {
      headers: { Authorization: `Bearer ${newToken}` },
    });
    if (response.status === 400 || response.status === 500) {
      throw response;
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// patctData with token
const patchDatawithToken = async (url, newToken, data) => {
  // console.log(url, newToken, data , "dlld")
  try {
    const response = await axios.patch(baseURL + url, data, {
      headers: { Authorization: `Bearer ${newToken}` },
    });
    if (response.status === 400 || response.status === 500) {
      throw response;
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// getData
const getData = async (url) => {
  try {
    const response = await axios.get(baseURL + url, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 400 || response.status === 500) {
      throw response;
    } else if (response?.status === 401) {
      sessionStorage.removeItem("biller_access");
      localStorage.removeItem("module_access_bou");
      window.location.href = "/login";
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// getDatafromBou
const getDataFromBOU = async (url) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "69420",
      },
    });
    if (response.status === 400 || response.status === 500) {
      throw response;
    } else if (response?.status === 401) {
      sessionStorage.removeItem("biller_access");
      localStorage.removeItem("module_access_bou");
      window.location.href = "/login";
    }
    return response;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    if (
      err.response &&
      err.response.status === 400 &&
      errorMessage === "Token invalid or expired"
    ) {
      // Remove token from cookies storage
      sessionStorage.removeItem("biller_access");

      localStorage.removeItem("module_access_bou");
      // Redirect to login page
      window.location.href = "/login";
    } else {
      throw errorMessage;
    }
  }
};

// addData
const postData = async (url, data) => {
  return handleApiCallWithToken(url, "post", data);
};
const patchData = async (url, data) => {
  return handleApiCallWithToken(url, "patch", data);
};

// editData
const putData = async (url, data) => {
  return handleApiCallWithToken(url, "put", data);
};

// deleteData
const deleteData = async (url) => {
  return handleDeleteApiCallWithToken(url, "delete");
};

// patchApi
const patchApi = async (url) => {
  return handleApiCallWithToken(url, "patch");
};

export {
  postWithOutToken,
  getData,
  postData,
  putData,
  deleteData,
  patchApi,
  getDataFromBOU,
  patchData,
  getDatawithToken,
  postDatawithToken,
  patchDatawithToken,
};
