// actions.js
const actions = {
  ADD_COMMUNICATION_RESPONSE: 'ADD_COMMUNICATION_RESPONSE',
  ADD_COMMUNICATION_RESPONSE_SUCCESS: 'ADD_COMMUNICATION_RESPONSE_SUCCESS',
  ADD_COMMUNICATION_RESPONSE_FAILED: 'ADD_COMMUNICATION_RESPONSE_FAILED',
  RESET_COMMUNICATION:"RESET_COMMUNICATION",

  GET_MAILS_DATA: 'GET_MAILS_DATA',
  GET_MAILS_DATA_SUCCESS: 'GET_MAILS_DATA_SUCCESS',
  GET_MAILS_DATA_FAILED: 'GET_MAILS_DATA_FAILED',

  GET_ORGANIZATIONS_DATA: 'GET_ORGANIZATIONS_DATA',
  GET_ORGANIZATIONS_DATA_SUCCESS: 'GET_ORGANIZATIONS_DATA_SUCCESS',
  GET_ORGANIZATIONS_DATA_FAILED: 'GET_ORGANIZATIONS_DATA_FAILED',

  addCommunicationResponse: (data) => ({
      type: actions.ADD_COMMUNICATION_RESPONSE,
      payload: { data },
  }),

  addCommunicationResponseSuccess: (message) => ({
      type: actions.ADD_COMMUNICATION_RESPONSE_SUCCESS,
      payload: { message },
  }),

  addCommunicationResponseFailed: (error) => ({
      type: actions.ADD_COMMUNICATION_RESPONSE_FAILED,
      payload: { error },
  }),
  resetCommunication: ()=>({
    type:actions.RESET_COMMUNICATION,
  }),
  

  getMailsData: (offset, limit, search, fromDate, toDate, agentId) => ({
    type: actions.GET_MAILS_DATA,
    payload: { offset, limit, search, fromDate, toDate, agentId },
  }),

  getMailsDataSuccess: (data, totalData) => ({
    type: actions.GET_MAILS_DATA_SUCCESS,
    payload: { data, totalData },
  }),

  getMailsDataFailed: (error) => ({
    type: actions.GET_MAILS_DATA_FAILED,
    payload: { error },
  }),

  getOrganizationsData: (offset, limit, search, fromDate, toDate) => ({
    type: actions.GET_ORGANIZATIONS_DATA,
    payload: { offset, limit, search, fromDate, toDate},
  }),

  getOrganizationsDataSuccess: (data, totalData) => ({
    type: actions.GET_ORGANIZATIONS_DATA_SUCCESS,
    payload: { data, totalData },
  }),

  getOrganizationsDataFailed: (error) => ({
    type: actions.GET_ORGANIZATIONS_DATA_FAILED,
    payload: { error },
  }),

};



export default actions;
