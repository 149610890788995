import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SeeAll from "../../../assets/icons/seeAll.svg"
import communicationChannelActions from "../../../redux/communication_channel/actions";

function SimplData() {
  const [agentName,setAgentName] = useState("");
  const dispatch = useDispatch();
  const { organizations } = useSelector(
    (state) => state.communicationReducer
  );
  const { agentId,agentsData } = useSelector((state) => ({
    agentId: state.authReducer.agentId,
    agentsData: state.authReducer?.totalAgent?.organizations
  }));


  // useEffect(() => {
  //   const updatedAgentName = agentsData?.find((item)=>item?.orgId===agentId)?.companyName
  //   setAgentName(updatedAgentName)
  //   setTimeout(()=>{
  //     if(!agentId || agentId===""){
  //       dispatch(communicationChannelActions.getOrganizationsData(0, 10))
  //     }else{
  //       dispatch(communicationChannelActions.getOrganizationsData(0, 10, updatedAgentName))
  //     }
  //   },2000)
  //   // debugger
  // }, [agentId,agentName,dispatch])

  
  const [totalBillersState,setTotalBillersState] = useState(0);
  const [billerDataState, setBillerDataState] = useState({
    fromTodayToLastMonth:"",
    last3Months:"",
    last6Months:""
  });

  // Helper function to calculate the date range
  const getDateRange = (monthsAgo) => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setMonth(today.getMonth() - monthsAgo);
    return { startDate: targetDate, endDate: today };
  };

  // Filter function
  const filterByDateRange = (data, monthsAgo) => {
    const { startDate, endDate } = getDateRange(monthsAgo);

    return data.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return createdAtDate >= startDate && createdAtDate <= endDate;
    });
  };

  useEffect(() => {
    const getOnlyBillers = organizations.filter((org)=> (org?.type?.toLowerCase() === "biller" && org.orgId));  
    setTotalBillersState(getOnlyBillers.length)

    
    // Filter data
    const fromTodayToLastMonth = filterByDateRange(getOnlyBillers, 1); // Last month
    const last3Months = filterByDateRange(getOnlyBillers, 3);          // Last 3 months
    const last6Months = filterByDateRange(getOnlyBillers, 6);          // Last 6 months

    setBillerDataState({
      fromTodayToLastMonth:fromTodayToLastMonth.length,
      last3Months:last3Months.length,
      last6Months:last6Months.length
    });

    // eslint-disable-next-line 
  }, [organizations])


  return (
    <>
      {/* SIMPL */}
      <div>
        {/* <h1 className="headingCard">SIMPL<span>Powered by NPCI UPMS</span></h1>
        <div className="nextCard">
          <div>Total Amount</div>
          <span>₹150,000</span>
        </div> */}
        {/* next card */}
        {/* <div className="nextHeadingTag">
          <h1>Billers</h1>
          <div>See All <img src={SeeAll}></img></div>
        </div> */}
      </div>

      {/* Billers */}
      <div>
        <div className="nextCard">
          <div>Total Billers</div>
          <span>{agentsData?.length}</span>
        </div>
        {/* <div className="lastcardSection">
          <div>
            <h1>Last month</h1>
            <h2>{billerDataState.fromTodayToLastMonth}</h2>
          </div>
          <div>
            <h1>3 Month</h1>
            <h2>{billerDataState.last3Months}</h2>
          </div>
          <div>
            <h1>6 Month</h1>
            <h2>{billerDataState.last6Months}</h2>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default SimplData;
