import React from "react";
import { DatePicker, Select, Button } from "antd";

const { Option } = Select;

const UFilter = ({ handleSubmit, setFormData, formData }) => {
  const isFormValid = formData.cycleNo && formData.fileType && formData.date;

  return (
    <div className="form-container">
      <Select
        placeholder="Select File Type"
        className="w-50"
        onChange={(value) => setFormData({ ...formData, fileType: value })}
      >
        {/* <Option value="JSON">JSON File</Option> */}
        <Option value="Match">MATCH File</Option>
        <Option value="Unmatch">UNMATCH File</Option>
        <Option value="SAL_File">SAL File</Option>
        <Option value="XML">XML File</Option>
        <Option value="Missing">Missing File</Option>
      </Select>
      <Select
        placeholder="Select Cycle No."
        className="w-48"
        onChange={(value) => setFormData({ ...formData, cycleNo: value })}
      >
        {[...Array(8)].map((_, i) => (
          <Option key={i} value={i + 1}>
            Cycle {i + 1}
          </Option>
        ))}
      </Select>
      <DatePicker
        className="w-48"
        onChange={(date, dateString) => setFormData({ ...formData, date: dateString })}
        placeholder="Select a date"
      />
      <Button
        type="primary"
        className="filter-button"
        onClick={handleSubmit}
        disabled={!isFormValid}
      >
        Submit
      </Button>
    </div>
  );
};

export default UFilter;
