import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import chartAction from "../../../redux/transactions/actions";
import moment from "moment"; // Import moment.js
import "./index.scss";

const { Option } = Select;
const { getDailyChartData } = chartAction;

const AllInOneChart = () => {
  const dispatch = useDispatch();
  const { dailyChartData } = useSelector((state) => state.transactionReducer);
  const { agentId } = useSelector((state) => state.authReducer);

  const [selectedOption, setSelectedOption] = useState("daily");
  const [selectedOption2, setSelectedOption2] = useState("Transactions"); // To track which data to show (Amount/Transactions)
  const [series, setSeries] = useState([]); // Empty array initially, will fill based on the selection
  const [categories, setCategories] = useState([]); // Default empty categories
  const [options, setOptions] = useState({
    chart: {
      width: "100%",
      type: "line", // Set chart type to line
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight", // Smooth curve for lines
      width: [3, 3, 3], // Width for three lines (Amount, Success, Failed)
    },
    grid: {
      row: {
        colors: ["transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [], // Initially empty categories, will be set dynamically
      title: {
        text: "Time", // Label for time on x-axis (dynamic values)
      },
      tickAmount: 15,
      labels: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: true,
        formatter: function (val) {
          return val?.toFixed(0);
        },
      },
      title: {
        text: selectedOption2 === "Amount" ? "Amount" : "Transactions", // Adjust y-axis title based on selection
      },
    },
    markers: {
      strokeWidth: 2,
      stroke: "#ffffff",
    },
    dataLabels: {
      enabled: false, // Disable the data labels on the markers (dots)
    },
  });

  const handleSelectChange = (event) => {
    setSelectedOption(event);
  };

  const handleSelectChange2 = (event) => {
    setSelectedOption2(event);
  };

  useEffect(() => {
    if (!agentId || agentId === "") {
      dispatch(getDailyChartData());
    } else {
      dispatch(getDailyChartData(agentId));
    }
  }, [agentId, dispatch]);

  useEffect(() => {
    if (dailyChartData?.data !== null && dailyChartData?.data[selectedOption]) {
      const dataSet = dailyChartData?.data[selectedOption];
      if (dataSet) {
        // Get the current hour (to compare against end times of time ranges)
        const currentHour = moment().hour();

        // Filter data based on the time ranges
        const filteredDataSet =
          selectedOption !== "daily"
            ? dataSet
            : dataSet.filter((entry) => {
                // Assuming time is in format "16:00-17:00"
                const timeRange = entry?.time;
                const [startTime, endTime] = timeRange.split("-"); // Split into start and end time

                // Get the end time and convert it to a moment object
                const entryEndTime = moment(endTime, "HH:mm").hour();
                // Only include data if the entry's end time is before or equal to the current hour
                return entryEndTime <= currentHour;
              });

        // Safely extract categories (times)
        const labels =
          selectedOption === "daily"
            ? filteredDataSet
                ?.filter(
                  (entry) =>
                    moment(entry?.time.split("-")[0], "HH:mm").hour() <=
                    currentHour
                )
                .map((entry) => {
                  return entry?.time; // Display the time range as the category
                })
            : filteredDataSet?.map((entry) => {
                return entry?.time; // Display the time range as the category
              });

        // Safely extract the amount and transaction data
        const amountData =
          selectedOption === "daily"
            ? filteredDataSet
                ?.filter((item, index) => item[index] !== labels[index])
                ?.map((entry) => entry?.data?.amount / 100 || 0)
            : filteredDataSet?.map((entry) => entry?.data?.amount / 100 || 0);
        const successTransactionData =
          selectedOption === "daily"
            ? filteredDataSet
                ?.filter((item, index) => item[index] !== labels[index])
                ?.map((entry) => entry?.data?.successTransactions || 0)
            : filteredDataSet?.map(
                (entry) => entry?.data?.successTransactions || 0
              );
        const failedTransactionData =
          selectedOption === "daily"
            ? filteredDataSet
                ?.filter((item, index) => item[index] !== labels[index])
                ?.map((entry) => entry?.data?.failedTransactions || 0)
            : filteredDataSet?.map(
                (entry) => entry?.data?.failedTransactions || 0
              );

        // Update categories and series based on selected options
        setCategories(labels);

        if (selectedOption2 === "Amount") {
          setSeries([
            {
              name: "Amount",
              data: amountData,
              color: "#1E90FF", // Blue for Amount
            },
          ]);
        } else if (selectedOption2 === "Transactions") {
          setSeries([
            {
              name: "Success Transactions",
              data: successTransactionData,
              color: "#00C851", // Green for Success Transactions
            },
            {
              name: "Failed Transactions",
              data: failedTransactionData,
              color: "#F44336", // Red for Failed Transactions
            },
          ]);
        }

        setOptions((prevOptions) => ({
          ...prevOptions,
          xaxis: {
            ...prevOptions.xaxis,
            categories: labels, // Set categories dynamically
          },
          yaxis: {
            ...prevOptions.yaxis,
            title: {
              text: selectedOption2 === "Amount" ? "Amount" : "Transactions",
            },
          },
        }));
      }
    }
  }, [dailyChartData, selectedOption, selectedOption2]);

  return (
    <>
      <div className="headingGrpah">
        <h4>Billing Cycle</h4>
        {/* {dailyChartData?.data?.oneYear && dailyChartData?.data?.oneMonth && (
          <> */}
        <div className="d-flex gap-1">
          <Select
            value={selectedOption}
            onChange={handleSelectChange}
            style={{ width: 120 }}
          >
            <Option value="allData">All Data</Option>
            <Option value="daily">Daily</Option>
            <Option value="oneWeek">One Week</Option>
            <Option value="oneMonth">One Month</Option>
            <Option value="threeMonths">3 Months</Option>
            <Option value="sixMonths">6 Months</Option>
            <Option value="oneYear">One Year</Option>
            <Option value="fiveYears">5 Year</Option>
          </Select>
          <Select
            value={selectedOption2}
            onChange={handleSelectChange2}
            style={{ width: 120 }}
          >
            <Option value="Transactions">Transactions</Option>
            <Option value="Amount">Amount</Option>
          </Select>
        </div>
        {/* </>
        )} */}
      </div>

      <div className="cssGraph">
        <div id="chart-area">
          <ReactApexChart
            options={options}
            series={series}
            type="line" // Use line chart type
            height={480}
          />
        </div>
      </div>
    </>
  );
};

export default AllInOneChart;
