import React, { useEffect, useState } from "react";
import { DatePicker, Select, Button, Upload , message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import  actions  from "../../../redux/ReconAndSettlement/actions";
import DnD from './Dnd';
import "./index.scss";


const { Option } = Select;
const { getReconData, getReconDataReset, getReconDataNotified } = actions;
const Filter = ( { selectedFile, setSelectedFile, loading }) => {
 
  const dispatch = useDispatch();
  const [messageApi,contextHolder]=message.useMessage()
  const [dropdown1Value, setDropdown1Value] = useState(null);
  const [dropdown2Value, setDropdown2Value] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [file, setFile] = useState(null);
  // Handlers
  const handleDropdown1Change = (value) => setDropdown1Value(value);
  const handleDropdown2Change = (value) => setDropdown2Value(value);
  const handleDateChange = (date, dateString) => setSelectedDate(dateString);

  // reducer usage
  const { fetchReconData } = useSelector((state) => {
    return {
      fetchReconData: state.reconReducer?.fetchReconData,
    };
  });

  const handleFormSubmit = () => {
    if (dropdown1Value && dropdown2Value && selectedDate) {
      setSelectedFile({
        cycleNo: dropdown2Value,
        fileType: dropdown1Value,
        date: selectedDate,
      });
      dispatch(getReconData({
        cycleNo :dropdown2Value,
        date : selectedDate, 
        fileType : dropdown1Value}));
    } else {
      message.warning("Please fill in all fields.");
    }
  };

  useEffect(() => {
    if (fetchReconData?.loading === true && fetchReconData?.error === null) {
      messageApi.destroy();
      messageApi.open({
        type: "loading",
        content: "fetching....",
      });
    } else if (
      fetchReconData?.error !== null &&
      !fetchReconData?.data?.message?.length > 0 &&
      fetchReconData?.loading === false
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "error",
          content:
            fetchReconData?.error?.length > 0 &&
            fetchReconData?.error !== null &&
            fetchReconData?.error,
          duration: 1,
        })
        .then(() => dispatch(getReconDataReset()));
    } else if (
      fetchReconData?.data?.message?.length > 0 &&
      fetchReconData?.error === null &&
      fetchReconData?.loading === false &&
      fetchReconData?.notified === false
    ) {
      if (!fetchReconData?.data?.url.length > 0) {
        messageApi.destroy();
        messageApi
          .open({
            type: "info",
            content: "No transaction sheet url available",
            duration: 3,
          })
          .then(() => dispatch(getReconDataNotified()));
      } else {
        messageApi.destroy();
        messageApi
          .open({
            type: "success",
            content:
              fetchReconData?.data?.message?.length > 0 &&
              fetchReconData?.data?.url?.length > 0 &&
              fetchReconData?.data?.message,
            duration: 1,
          })
          .then(() => dispatch(getReconDataNotified()));
      }
    }
  }, [fetchReconData]);
 
  return (
    <div className="filter-container">
      {contextHolder}
      {showForm ? (
        <div className="form-container">
          <Select
            placeholder="Select File Type"
            className="filter-select"
            onChange={handleDropdown1Change}
          >
            <Option value="XML">XML File</Option>
            <Option value="JSON">JSON File</Option>
            <Option value="Match">MATCH File</Option>
            <Option value="Unmatch">UNMATCH File</Option>
            <Option value="SAL_File">SAL File</Option>
            <Option value="Transaction_Report">Transaction Report</Option>
          </Select>

          <Select
            placeholder="Select Cycle No."
            className="filter-select"
            onChange={handleDropdown2Change}
          >
            {[...Array(8)].map((_, i) => (
              <Option key={i} value={i + 1}>
                Cycle {i + 1}
              </Option>
            ))}
          </Select>

          <DatePicker
            className="filter-date-picker"
            onChange={handleDateChange}
            placeholder="Select a date"
          />

          <Button  onClick={handleFormSubmit} className="filter-button">
            {loading===true?<Spin size="20"/>:"Submit"}
          </Button>
        </div>
      ) : (
        <div className="upload-container">
          <Upload>
          <DnD setFormDataState={setFile}/>
          </Upload> 
        </div>
      )}
    </div>
  );
};

export default Filter;
