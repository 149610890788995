import React from "react";
import "./overview.scss";
import TransactionData from "./overviewComponents/TransactionData";
import BillerPerformance from "./overviewComponents/BillerPerformance";
import BillingCycleGraph from "./overviewComponents/BillingCycleGraph";
import SimplData from "./overviewComponents/SimplData";
import AllInOneGraph from "./overviewComponents/AllInOneGraph";
import { PaymentModeChart } from "./overviewComponents/PieChart";
import { TransactionStatusChart } from "./overviewComponents/PieChart";

function Overview() {
  return (
    <div className="main">
      <div className="part1">
        <TransactionData />
      </div>
      <div className="part2">
        <div className="internalPart2">
          <div className="piechart">
          {/* <div className=""> */}
            {" "}
            {/* <BillingCycleGraph /> */}
            <PaymentModeChart />
            <TransactionStatusChart />
          </div>
          {/* <div className="w-100">
            <SimplData />
          </div> */}
        </div>
        {/* <BillerPerformance /> */}
      </div>
      <div className="part3">
        <AllInOneGraph />
      </div>
    </div>
  );
}

export default Overview;
