import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {},
};

export const errorPopup = (message) => {
    toast.error(message, {
        ...baseToastOptions,
        style: getResponsiveStyle(),
    });
};

export const successPopup = (message) => {
    toast.success(message, {
        ...baseToastOptions,
        style: getResponsiveStyle(),
    });
};

export const loginPopup = (message) => {
    toast.success(message, {
        ...baseToastOptions,
    });
};

export const warningPopup = (message) => {
    toast.warn(message, {
        ...baseToastOptions,
        style: getResponsiveStyle(),
    });
};

const getResponsiveStyle = () => {
    return {
        // Example responsive styles; adjust as needed
        marginTop: window.innerWidth < 600 ? '60px' : '50px',
        width: window.innerWidth < 600 ? '70%' : 'auto',
        left: window.innerWidth < 600 ? '30%' : 'auto',
        right: window.innerWidth < 600 ? '5%' : 'auto',
    };
};

// Add an event listener to update styles on window resize
window.addEventListener('resize', () => {
    const toastContainers = document.querySelectorAll('.Toastify__toast-container');
    toastContainers.forEach(container => {
        container.style.marginTop = window.innerWidth < 600 ? '60px' : '50px';
        container.style.width = window.innerWidth < 600 ? '70%' : 'auto';
        container.style.left = window.innerWidth < 600 ? '30%' : 'auto';
        container.style.right = window.innerWidth < 600 ? '5%' : 'auto';
    });
});

