import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tag, Table, message } from "antd";
import emailActions from '../../redux/communication_channel/actions'
import moment from "moment";
import SearchBar from "../../utils/Searchbar";
import UserModal from "./communicationModel/index";

const columns = [
    {
        title: "#",
        dataIndex: "key",
        key: "key",
        width: 100,
    },
    {
        title: "Company Name",
        dataIndex: "companyName",
        sorter: (a, b) => a.companyName.localeCompare(b.name),
        width: 150,
    },
    {
        title: "EMAIL",
        dataIndex: "email",
        width: 200,
    },
    {
        title:"Users",
        sorter:(a,b)=>b?.users-a?.users,
        dataIndex:"users",
        width:200
    },
    {
        title: "CREATED DATE",
        dataIndex: "created_date",
        width: 150,
    },
    {
        title: "STATUS",
        dataIndex: "status",
        width: 100,
        render: (text) => (
            <Tag color={text === "Active" ? "green" : "red"}>
                {text === "Active" ? "Active" : "Deactive"}
            </Tag>
        ),
    },
];


const { addCommunicationResponse , getOrganizationsData, resetCommunication } = emailActions;
function Communicationchannel() {
    const dispatch = useDispatch();
    const { organizations, loading, totalData, error, communicationResponses } = useSelector((state) => state.communicationReducer);
    // console.log(organizations , "organizations")

    const [tableParams, setTableParams] = useState({
        current: 1,
        pageSize: 10,
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDetails, setUserDetails] = useState([]);
    const [messageApi,contextHolder]=message.useMessage()

    useEffect(() => {
        dispatch(getOrganizationsData());
    }, [dispatch]);

    const dataSource = organizations?.map((user, index) => ({
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        companyName: user.companyName,
        email: user.email,
        users:user.usersCount,
        created_date: moment(new Date(user.createdAt)).format("MM/DD/YYYY"),
        status: user.status,
        billerId: user.orgId,
        type : user.type
    }));

    let timeoutId;
    const handleFilterChange = (e) => {
        e.preventDefault();
        const value = e.target.value;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            if (organizations?.length >= 0) {
                dispatch(getOrganizationsData(0, 10, value));
            }
        }, 1000);
    };

    const handleTableChange = (pagination) => {
        const { current, pageSize } = pagination;
        setTableParams({ current, pageSize });
        dispatch(getOrganizationsData(current - 1, pageSize));
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys, selectedRows) => {
            setSelectedRowKeys(selectedKeys);
            setSelectedRowsData(selectedRows?.map(row => row?.email));
            setUserDetails(selectedRows?.map(row => ({
                email: row.email,
                billerId: row.billerId
            })));
        },
    };

    const showModal = () => {
        if (selectedRowsData.length === 0) {
            message.warning("Please select at least one user.");
            return;
        }
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedRowKeys([])
        setSelectedRowsData([])
    }

    const handleSubmit = async (data) => {
        const newData = {
            ...data,
            email:data.email
        }
        dispatch(addCommunicationResponse(newData)); 
        setTimeout(() => {
        setIsModalOpen(false);
        setSelectedRowKeys([]);
            setSelectedRowsData([]);
            setUserDetails([]);
        }, 1000)
    };

    // useEffect(() => {
    //   let timeout;
    //   if (error !== null) {
    //     messageApi.open({
    //       type: "error",
    //       content: error?.includes("failed to send")&&"Failed to send email",
    //       duration: 1,
    //     });
    //     timeout = setTimeout(() => {
    //       dispatch(resetCommunication());
    //     }, 1000);
    //   } else if (communicationResponses.length > 0) {
    //     messageApi.open({
    //       type: "success",
    //       content:
    //         communicationResponses[0].length > 0 && communicationResponses[0],
    //       duration: 1,
    //     });
    //     timeout = setTimeout(() => {
    //       dispatch(resetCommunication());
    //     }, 1000);
    //   }
    //   return () => {
    //     if (timeout) {
    //       clearTimeout(timeout);
    //     }
    //   };
    // }, [error, messageApi]);

    return (
        <>
        {contextHolder}
            <div className="mainContain">
                <h1 className="transaction">Communication channel</h1>
                <div className="d-flex justify-content-between align-items-center">
                <SearchBar onChange={handleFilterChange} />
                    {selectedRowsData.length > 0 && (
                        <Button
                            style={{ backgroundColor: "#073763", borderColor: "#073763", color: "white" }}
                            size="large"
                            onClick={showModal}
                        >
                            Send Email
                        </Button>
                    )}
                </div>
                <div className="mt-3">
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        loading={loading}
                        pagination={{
                            total: totalData,
                            current: tableParams.current,
                            pageSize: tableParams.pageSize,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "20", "50"],
                        }}
                        onChange={handleTableChange}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>

            <UserModal
                title="Send Email"
                open={isModalOpen}
                confirmLoading={false}
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                emailList={selectedRowsData}
                billerDetails={userDetails}
            />
        </>
    );
}

export default Communicationchannel;
