import actions from "./actions";
import { all, takeEvery, put, call } from "redux-saga/effects";
// import {fetchReconFile} from "../../Services/ApiMethodHelper";
import { getDataFromBOU, postData } from "../../Services/ApiMethodHelper";
const baseURL = "https://prod.recon.idfc.plutos.one/";
// const baseURL = "https://helpful-enough-dingo.ngrok-free.app/";

function* getReconDataResponse({ payload: { cycleNo, date, fileType } }) {
  try {
    const response = yield call(
      getDataFromBOU,
      `${baseURL}file-fetch?cycleNo=${cycleNo}&date=${date}&fileType=${fileType}`
    );

    if (response.status === 201 || response.status === 200) {
      yield put(actions.getReconDataSuccess(response?.data));
    } else {
      yield put(actions.getReconDataFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.getReconDataFailure(error?.response?.data || error));
  }
}

function* uploadReconData({ payload }) {
  const { file } = payload;
  try {
    const response = yield call(postData, `${baseURL}file-upload`, file);

    if (response.status === 201 || response.status === 200) {
      yield put(actions.UploadFileSuccess(response?.data));
    } else {
      yield put(actions.UploadFileFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.UploadFileFailure(error?.response?.data || error))
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_FILE, getReconDataResponse)]);
  yield all([takeEvery(actions.UPLOAD_FILE, uploadReconData)]);
}
