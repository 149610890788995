import { Input } from 'antd';

function SearchBar({ onChange, style }) {
    return (
        <div className="input-group" style={{ maxWidth: '350px' }}>
            <Input size="default size" placeholder ="Type here to search..." onChange={onChange} style={{width:'300px',padding:'8px',outline:'none',border:'0px'}} />

        </div>
    );
}

export default SearchBar;
