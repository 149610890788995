import React, { useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import AuthActions from '../../redux/auth/actions';
import { Link } from 'react-router-dom';
import './LoginPage.scss';
import Verify from './OTPVerifyPage';

const { login, authReset } = AuthActions;

function Login() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const { loginLoading, loginError, loginData } = useSelector((state) => state.authReducer);

    useEffect(() => {
        if (loginError !== null && loginError !== undefined) {
            errorHandle(loginError);
        }
        if (loginData !== null && loginData !== undefined) {
        }
    }, [loginError]);

    const onFinish = (values) => {
        dispatch(login(values));
    };

    const errorHandle = (text) => {
        const hide = messageApi.open({
            type: 'error',
            content: text,
        });
        setTimeout(hide, 5000);
        dispatch(authReset());
    };

    return (
        <div className="login-main-div flex justify-center items-center h-screen">
            <section className="container flex justify-center items-center h-full">
                <div className="row flex justify-center items-center w-full">
                    <div className="row login-container flex justify-center items-center w-full">
                        <div className="col-md-8 col-lg-7 col-xl-6 login-image">
                            <img src={`/forgot-password.svg`} className='img-fluid ' alt='forgot-password'/>
                        </div>
                        {loginData && loginData.loginData ?
                            <Verify loginData={loginData.loginData} /> :
                            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1 login-form">
                                {contextHolder}
                                <Form
                                    form={form}
                                    name="loginForm"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    layout="vertical"
                                >
                                    <h1>Login</h1>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[ 
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            },
                                            { type: 'email', message: 'Please input valid email id!' }
                                        ]}
                                    >
                                        <Input placeholder='Enter your email' autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Please input your password!' }]}
                                    >
                                        <Input.Password placeholder='Enter your password' autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={loginLoading}>
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <Link to="/forget-password" className="small text-muted">Forgot password?</Link>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
