// communicationReducer.js
import actions from './actions';

const initialState = {
    communicationResponses: [],
    loading: false,
    error: null,
    message: null,
    buttonLoading: false,
    mailsData: {},
    totalData: null,
    organizations:[],
    show: false,
};

const communicationReducer = (state = initialState, action) => {
        switch (action.type) {
        case actions.ADD_COMMUNICATION_RESPONSE:
            return {
                ...state,
                loading: true,
            };
        case actions.ADD_COMMUNICATION_RESPONSE_SUCCESS:
            return {
                ...state,
                loading: false,
                communicationResponses: [...state.communicationResponses, action.payload.message],
            };
        case actions.ADD_COMMUNICATION_RESPONSE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case actions.RESET_COMMUNICATION:
          return {
            ...state,
            loading:false,
            error:null,
            communicationResponses:[]
          }
        case actions.GET_MAILS_DATA:
      return {
        ...state,
        loading: true,
      };
        case actions.GET_MAILS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        mailsData: action.payload.data,
        totalData: action.payload.totalData,
      };
        case actions.GET_MAILS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      case actions.GET_ORGANIZATIONS_DATA:
        return {
          ...state,
          loading: true,
        };
          case actions.GET_ORGANIZATIONS_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          organizations: action.payload.data.organizations,
          totalData: action.payload.totalData,
        };
          case actions.GET_ORGANIZATIONS_DATA_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
        default:
            return state;
    }
};

export default communicationReducer;
