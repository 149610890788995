import actions from "./actions";

const initState = {
  message: null,
  buttonLoading: false,
  show: false,
  chartData: {
    loading: false,
    data: null,
    error: null,
  },
  dailyChartData: {
    loading: false,
    data: null,
    error: null,
  },
  pieChartData: {
    loading: false,
    data: null,
    error: null,
  },
  transactionData: {
    loading: false,
    data: null,
    error: null,
  },
  allTransactions: {
    loading: false,
    data: null,
    error: null,
  },
  fullTransactionDetails: {
    loading: false,
    data: {},
    error: null,
  },
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.GET_TRANSACTIONS_DATA:
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          loading: true,
        },
      };
    case actions.GET_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          loading: false,
          data: action.payload?.data,
          error: null,
        },
      };
    case actions.GET_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        transactionData: {
          ...state.transactionData,
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };

    case actions.GET_ALL_TRANSACTIONS_DATA:
      return {
        ...state,
        allTransactions: {
          ...state.allTransactions,
          loading: true,
        },
      };
    case actions.GET_ALL_TRANSACTIONS_DATA_SUCCESS:
      return {
        ...state,
        allTransactions: {
          ...state.allTransactions,
          loading: false,
          data: action.payload.data, // Corrected to 'data' from 'transactionData'
          error: null,
        },
      };
    case actions.GET_ALL_TRANSACTIONS_DATA_FAILED:
      return {
        ...state,
        allTransactions: {
          ...state.allTransactions,
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case actions.RESET_ALL_TRANSACTIONS_DATA:
      return {
        ...state,
        allTransactions: {
          loading: false,
          data: null,
          error: null,
        },
      };

    case actions.GET_CHART_DATA:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          loading: true,
        },
      };
    case actions.GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actions.GET_CHART_DATA_FAILED:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case actions.GET_DAILY_CHART_DATA:
      return {
        ...state,
        dailyChartData: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case actions.GET_DAILY_CHART_DATA_SUCCESS:
      return {
        ...state,
        dailyChartData: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actions.GET_DAILY_CHART_DATA_FAILED:
      return {
        ...state,
        dailyChartData: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case actions.GET_DAILY_CHART_DATA_RESET:
      return {
        ...state,
        dailyChartData: {
          loading: false,
          data: null,
          error: null,
        },
      };
    case actions.GET_PIE_CHART_DATA:
      return {
        ...state,
        pieChartData: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case actions.GET_PIE_CHART_DATA_SUCCESS:
      return {
        ...state,
        pieChartData: {
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actions.GET_PIE_CHART_DATA_FAILED:
      return {
        ...state,
        pieChartData: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case actions.GET_PIE_CHART_DATA_RESET:
      return {
        ...state,
        pieChartData: {
          loading: false,
          data: null,
          error: null,
        },
      };
    case actions.GET_FULL_TRANSACTIONS_DETAILS:
      return {
        ...state,
        fullTransactionDetails: {
          ...state.fullTransactionDetails,
          loading: true,
          error: null,
          data: {},
        },
      };
    case actions.GET_FULL_TRANSACTIONS_DETAILS_SUCCESS:
      return {
        ...state,
        fullTransactionDetails: {
          ...state.fullTransactionDetails,
          loading: false,
          data: action.payload.data,
          error: null,
        },
      };
    case actions.GET_FULL_TRANSACTIONS_DETAILS_ERROR:
      return {
        ...state,
        fullTransactionDetails: {
          ...state.fullTransactionDetails,
          loading: false,
          data: {},
          error: action.payload.error,
        },
      };
    case actions.GET_FULL_TRANSACTIONS_DETAILS_RESET:
      return {
        ...state,
        fullTransactionDetails: {
          ...state.fullTransactionDetails,
          loading: false,
          data: {},
          error: null,
        },
      };
    default:
      return state;
  }
}
