import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../redux/auth/actions";
// import pnbLogo from "../../assets/images/pnblogo.jpeg";
import bellIcon from "../../assets/icons/icon.svg";
import { IoNotifications } from "react-icons/io5"
import "./header.scss";
import {
  Dropdown,
  Tooltip,
  Input,
  Form,
  Button,
  Modal,
  message,
  ConfigProvider,
  Select,
  Popover,
} from "antd";
import Cookies from "js-cookie";
// import orgPng from "../../assets/images/org.png";
import mailpng from "../../assets/images/mail (1).png";
import resetPasswordpng from "../../assets/images/reset-password (1).png";
import logoutpng from "../../assets/images/log-out.png";
import { PASSWORD_VALIDATE } from "../../Services/constane";
import { CloseCircleOutlined } from "@ant-design/icons";

function Header() {
  const { getProfile, changePassword, authReset, getAgentData, selectAgentRequest } = authActions;
  const dispatch = useDispatch();
  const bankLogo = process.env.REACT_APP_IDFC_LOGO;
  const [notifications, setNotifications] = useState([])
  const [viewedNotifications, setViewedNotifications] = useState([])

  const data = []

  useEffect(() => {
    if (viewedNotifications?.length > 0) {
      setNotifications(data?.filter((i) => !viewedNotifications?.includes(i)))
    } else {
      setNotifications(data)
    }
  }, [viewedNotifications])


  const [messageApi, contextHolder] = message.useMessage();
  const profileData = useSelector((state) => state.authReducer.profileData);
  const { changePasswordMessage, changePasswordError, totalAgent, buttonLoading } = useSelector(
    (state) => ({
      changePasswordMessage: state.authReducer.changePasswordMessage,
      changePasswordError: state.authReducer.changePasswordError,
      totalAgent: state.authReducer.totalAgent,
      buttonLoading: state.authReducer.buttonLoading
    })
  );


  // console.log(totalAgent?.organizations?.map((agent,index) => (agent.companyName)) , "totalAgent")

  const [open, setOpen] = useState(false);
  const [passwordFormate, setPasswordFormate] = useState(true);
  const handleLogOut = () => {
    sessionStorage.removeItem("biller_access");
    localStorage.removeItem("module_access_bou");
    window.location.href = "/login";
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (changePasswordMessage !== null && changePasswordMessage !== undefined) {
      success(changePasswordMessage);
    }
    if (changePasswordError !== null && changePasswordError !== undefined) {
      errorHandle(changePasswordError);
    }
    // eslint-disable-next-line
  }, [changePasswordMessage, changePasswordError]);

  const success = (text) => {
    setOpen(false);
    const hide = messageApi.open({
      type: "success",
      content: text,
    });
    setTimeout(hide, 4000);
    dispatch(authReset());
  };

  const errorHandle = (text) => {
    const hide = messageApi.open({
      type: "error",
      content: text,
    });
    setTimeout(hide, 4000);
    dispatch(authReset());
  };

  const handleOpenPasswordFormModal = () => {
    setOpen(true);
  };

  const handlePassword = (e) => {
    e.preventDefault();
    if (PASSWORD_VALIDATE.test(e.target.value) || e.target.value === "") {
      setPasswordFormate(true);
    } else {
      setPasswordFormate(false);
    }
  };

  const notificationData = (
    <>
      {notifications.filter((i) => !viewedNotifications?.includes(i))?.length > 0 ? (
        <div className="notificationsData">
          {notifications
            ?.map((i, index) => (
              <div
              className="notification-item"
                key={index}
              >
                <span>
                  {i}
                </span>
                <CloseCircleOutlined
                  onClick={() => {
                    if (!viewedNotifications?.includes(i)) {
                      setViewedNotifications((prevViewedNotifications) => [...prevViewedNotifications, i]);
                    }
                  }}
                />
              </div>
            ))}
        </div>
      ) : (
        <span className="notificationsData">No New Notification</span>
      )}
    </>
  );

  const items = [
    {
      key: 1,
      label: (
        <div className="profileWrapper">
          <div className="profile">
            <div className="circle" style={{ cursor: "pointer" }}>
              <p className="circle-inner-profile">
                {profileData ? profileData?.name?.charAt(0).toUpperCase() : ""}
              </p>
            </div>
            <h3 className="name-profile">{profileData?.name}</h3>
            <p className="title">{profileData?.role}</p>
          </div>
          <div className="email-content">
            <span>
              <img src={mailpng} alt="" width="19" height="19" />
            </span>
            <span className="gmail-p">
              {" "}
              <a href={`mailto:${profileData?.email}`}>{profileData?.email}</a>
            </span>
          </div>
          {/* <div className="email-content">
            <span>
              <img src={orgPng} alt="" width="19" height="19" />
            </span>
            <span className="gmail-p">
              <span>{profileData?.org_id}</span>
            </span>
          </div> */}

          <div className="social-icons">
            <div
              className="email-content"
              onClick={handleOpenPasswordFormModal}
            >
              <span>
                <img src={resetPasswordpng} alt="" width="19" height="19" />
              </span>
              <span className="gmail-p"> Change Password</span>
            </div>

            <div className="email-content" onClick={handleLogOut}>
              <span>
                <img src={logoutpng} alt="" width="19" height="19" />
              </span>
              <span className="gmail-p"> Log Out</span>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const handleSubmit = (values) => {
    dispatch(changePassword(values));
  };
  const handleChange = (agentId) => {
    dispatch(selectAgentRequest(agentId))
  }
  useEffect(() => {
  //   // debugger
  //   dispatch(getProfile());
    dispatch(getAgentData())
    // eslint-disable-next-line
  }, []);

  // console.log(totalAgent , "ksfks")


  return (
    <div className="AppHeader">
      {contextHolder}
      <div>
        <img style={{position:'absolute',left:'3.8rem'}} className="logoIcon" src={bankLogo} alt="Bank logo"></img>
      </div>
      <div className="alignNotification">
        <div>
          <Select
            style={{ width: "300px" }}
            placeholder="Select a Biller"
            name="role_id"
            className="fixed-width-select"
            onChange={handleChange}
          >
            <Select.Option value="" key={-1}>
              Select a Biller
            </Select.Option>
            {totalAgent?.organizations?.length > 0 &&
              totalAgent?.organizations.map((agent, index) => (
                <Select.Option value={agent?.orgId} key={index}>
                  {agent?.companyName}
                </Select.Option>
              ))}
          </Select>
        </div>

        <Popover content={notificationData} title={`Notifications (${notifications?.filter((i) => !viewedNotifications?.includes(i))?.length})`} trigger={'click'}>
          <div style={{ cursor: 'pointer' }}>{notifications && notifications?.length > 0 ? <img className="imgIcon" src={bellIcon} alt="notification"></img> : <IoNotifications size={25} />}</div>
        </Popover>

        <Dropdown menu={{ items }} placement="topRight" trigger={["click"]}>
          <div className="circle" style={{ cursor: "pointer" }}>
            <p className="circle-inner mb-0">
              {profileData ? profileData?.name?.charAt(0).toUpperCase() : ""}
            </p>
          </div>
        </Dropdown>
      </div>

      <Modal
        title="Change Password"
        open={open}
        onOk={handleCancel}
        onCancel={handleCancel}
        closable={true}
        destroyOnClose={true}
        maskClosable={false}
        centered
        footer={[
          <Button key="back" onClick={handleCancel}>
            CANCEL
          </Button>,
          <Button
            form="passwordForm"
            key="submit"
            htmlType="submit"
            type="primary"
            loading={buttonLoading}
          >
            UPDATE
          </Button>,
        ]}
      >
        <Form
          id="passwordForm"
          onFinish={handleSubmit}
          layout="vertical"
          className="row-col"
        >
          <ConfigProvider>
            <Tooltip
              placement="bottom"
              title={
                "Password must be at least 8 characters, including at least one uppercase (A), one lowercase (a), one special character (#), and digits (12345)."
              }
            >
              <Form.Item
                className="username"
                label="New Password"
                name="password"
                rules={[
                  { required: true, message: "New password is required!" },
                  {
                    validator(_, value) {
                      if (passwordFormate === true) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Invalid password format!")
                      );
                    },
                  },
                ]}
              >
                <Input.Password
                  placeholder="New Password"
                  name="password"
                  hasFeedback
                  onChange={handlePassword}
                />
              </Form.Item>
            </Tooltip>
          </ConfigProvider>

          <Form.Item
            className="username"
            label="Confirm Password"
            name="confirmPassword"
            hasFeedback
            rules={[
              { required: true, message: "Confirm password is required!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Confirm password does not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handlePassword}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Header;
