import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DnD from "./Dnd";
import { message } from "antd";
import UFilter from "./UploadFilter";
import actions from "../../../redux/ReconAndSettlement/actions";

const Uploads = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    cycleNo: "",
    fileType: "",
    date: "",
  });
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const { uploadReconData } = useSelector((state) => {
    return {
      uploadReconData: state.reconReducer?.uploadReconData,
    };
  });

  const handleSubmit = () => {
    if (!file) {
      message.error("No file selected");
      return;
    }

    const form = new FormData();
    form.append("cycleNo", formData.cycleNo);
    form.append("fileType", formData.fileType);
    form.append("date", formData.date);
    form.append("file", file);

    dispatch(
      actions.UploadFile({
        cycleNo: formData.cycleNo,
        date: formData.date,
        fileType: formData.fileType,
        file: form,
      })
    );
  };

  useEffect(() => {
    if (uploadReconData?.loading === true && uploadReconData?.error === null) {
      messageApi.destroy();
      messageApi.open({
        type: "loading",
        content: "uploading.....",
      });
    } else if (
      uploadReconData?.loading === false &&
      uploadReconData?.error !== null
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "error",
          content: uploadReconData?.error !== null && uploadReconData?.error,
          duration: 1,
        })
        .then(() => dispatch(actions.uploadDataReset()));
    } else if (
      uploadReconData?.loading === false &&
      uploadReconData?.error === null &&
      uploadReconData?.notified === false &&uploadReconData?.data?.message?.length>0
    ) {
      messageApi.destroy();
      messageApi.open({
        type: "success",
        content: uploadReconData?.data?.message,
        duration: 1,
      }).then(()=>dispatch(actions.uploadDataReset()));
    }
  }, [uploadReconData]);

  useEffect(()=>{
    if(uploadReconData?.data?.message?.length>0){
      setFormData({
        cycleNo: "",
        fileType: "",
        date: "",
      })
      setFile(null)
    }
  },[uploadReconData?.data])

  return (
    <div>
      {contextHolder}
      <div className="filter-container">
        <UFilter
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
        />
        <DnD setFormDataState={setFile} />
      </div>
    </div>
  );
};

export default Uploads;
