import { all } from 'redux-saga/effects';
import getDataSagas from './GetBillerData/saga';
import getCategorySagas from './GetCategoryData/saga';
import getUserPerSagas from './user/saga';
import getAuthSagas from './auth/saga';
import GetTransactionSagas from "./transactions/saga";
import GetCommunicationSaga from "./communication_channel/saga";
import   getReconSaga   from './ReconAndSettlement/saga';


export default function* rootSaga() {
    yield all([
        getDataSagas(),
        getCategorySagas(),
        getAuthSagas(),
        getUserPerSagas(),
        GetTransactionSagas(),
        GetCommunicationSaga(),
        getReconSaga()
    ])
}