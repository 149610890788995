import { message } from "antd";

export const BlobFileDownload = async (fileUrl, accessToken) => {
  try {
    message.loading('Exporting...')
    // Ensure access token is provided
    if (!accessToken) {
      throw new Error("Access token is missing.");
    }
    // Fetch the file content with the authorization header
    const response = await fetch(fileUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch the file. Status: ${response.status}`);
    }

    // Convert response to a Blob
    const blob = await response.blob();

    if (blob.size === 0) {
      throw new Error("Received empty file.");
    }

    // Create a temporary URL for the Blob
    const downloadUrl = window.URL.createObjectURL(blob);

    // Extract the file name or use a default name
    const fileName = fileUrl.split("/").pop() || "downloaded-file.zip";

    // Create an anchor element and simulate a click
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);

    // Revoke the object URL
    window.URL.revokeObjectURL(downloadUrl);
    message.destroy()
    message.success('File exported successfully!')
    // Resolve the promise after successful download
    return "Download successful";
  } catch (error) {
    message.destroy();
    message.error({
      content: error?.message,
      duration: 2,
    });
    console.error("Download failed:", error.message);

    // Reject the promise with an error message
    return `Download failed: ${error?.message}`;
  }
};
