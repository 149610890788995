import actions from "./actions";

const initialState = {
  fetchReconData: {
    loading: false,
    data: {},
    error: null,
    notified: false,
  },
  uploadReconData: {
    loading: false,
    data: {},
    error: null,
    notified: false,
  },
};

export default function reconReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_FILE:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: true,
          notified:false,
          // data:action.payload,
          // error:null
        },
      };
    case actions.FETCH_DATA_SUCCESS:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: action.payload.data,
          error: null,
          notified: false,
        },
      };
    case actions.FETCH_DATA_FAILURE:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: {},
          error: action.payload.error,
          notified: false,
        },
      };
    case actions.FETCH_DATA_RESET:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: {},
          error: null,
          notified: false,
        },
      };
    case actions.FETCH_DATA_NOTIFIED:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          notified: true,
        },
      };
    case actions.UPLOAD_FILE:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          loading: true,
        },
      };
    case actions.UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          loading: false,
          data: action.payload.data,
          error: null,
          notified: false,
        },
      };
    case actions.UPLOAD_DATA_FAILURE:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          loading: false,
          data: {},
          error: action.payload.error,
          notified: false,
        },
      };
    case actions.UPLOAD_DATA_RESET:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          loading: false,
          data: {},
          error: null,
          notified: false,
        },
      };
    case actions.UPLOAD_DATA_NOTIFIED:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          notified: true,
        },
      };
    default:
      return state;
  }
}
